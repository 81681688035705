import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { AssetDimensions } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryData';
import User from 'components/data/User';

/** Error message when something goes while fetching an asset mime type. */
const MIME_TYPE_ERROR_MESSAGE = 'Error retrieving MIME type: ';

const MIME_TYPES: { [key: string]: string } = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    bmp: 'image/bmp',
    webp: 'image/webp',
    svg: 'image/svg+xml',
    tiff: 'image/tiff',
    ico: 'image/x-icon',
    heic: 'image/heic',
    bat: 'image/bat',
    apng: 'image/apng',
    avif: 'image/avif'
};

const EXTENSION_MAP: { [key: string]: string } = {
    'image/jpeg': '.jpg',
    'image/png': '.png',
    'image/gif': '.gif',
    'image/bmp': '.bmp',
    'image/webp': '.webp',
    'image/svg+xml': '.svg',
    'image/tiff': '.tiff',
    'image/x-icon': '.ico',
    'image/heic': '.heic',
    'image/bat': '.bat',
    'image/apng': '.apng',
    'image/avif': '.avif'
};

/**
 * Service to handle image downloads.
 */
class ImageFileService {
    /**
     * Downloads image and converts it to File type.
     * @param imageUrl Image URL.
     * @returns an instance of File type.
     */
    static getImageFileFromUrl = async (imageUrl: string) => {
        const response = await fetch(imageUrl);

        const blob = await response.blob(); // Convert response to blob.
        const filename = uuidv4(); // Generates a random name for the image file name.

        return new File([blob], filename, { type: blob.type }); // Return image as file.
    };

    /**
     * Convert base64 image to File type.
     * @param base64Image Image base64 string.
     */
    static convertBase64ImageToFile = (base64Image: string, explicitMime?: 'image/jpg' | 'image/png'): File | null => {
        let filename = uuidv4();
        const arr = base64Image.split(',');
        const bstr = atob(arr[1]);
        const mimeMatches = arr[0].match(/:(.*?);/);

        if (!mimeMatches) {
            return null;
        }

        const mime = explicitMime ? explicitMime : mimeMatches[1];

        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        const extension = EXTENSION_MAP[mime];

        // Filename
        if (extension) {
            filename = filename + extension;
        } else {
            // Handle unsupported MIME type
            filename = filename + '.png';
        }

        return new File([u8arr], filename, { type: mime });
    };

    /**
     * Converts a Blob to a base64 encoded string.
     * @param blob The Blob to convert.
     * @returns A Promise that resolves to the base64 encoded string.
     */
    static convertBlobToBase64 = (blob: Blob): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = () => reject();
            reader.readAsDataURL(blob);
        });
    };

    /**
     * Upload image to cloud as base64.
     * @param base64 Image the base 64 format.
     */
    static uploadBase64 = async (base64: string, explicitMime?: 'image/png' | 'image/jpg'): Promise<string | undefined> => {
        try {
            // We use cloud storage to upload
            if (process.env.APP_MEDIA_SERVICE_STORAGE === 'cloud') {
                const file = this.convertBase64ImageToFile(base64, explicitMime);

                if (!file) {
                    return '';
                }

                const uploadResult = await axios.post(
                    process.env.APP_MEDIA_URL + 'media/uploadToCloud',
                    { filename: file.name },
                    { headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` } }
                );

                const uploadUrl = uploadResult.data.uploadUrl;

                await axios.put(uploadUrl, file, {
                    headers: { 'Content-Type': file.type },
                    withCredentials: false
                });

                return uploadResult.data.url;
            }
            // We use API based storage
            else {
                const response = await axios.post('media/imageBase64', {
                    file: base64
                });
                return response.data.url;
            }
        } catch (e) {
            console.error(e);
        }
    };

    /**
     * Get image size from URL.
     * @param url - Image URL.
     * @returns Image size in KB.
     * @throws Error if the image size could not be retrieved.
     */
    static getImageFileSize = async (url: string): Promise<number | undefined> => {
        try {
            // Fetch the image
            const response = await fetch(url, {
                method: 'HEAD'
            });

            // Check if the response is ok (status in the range 200-299)
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Get the content length from the headers
            const contentLength = response.headers.get('content-length');

            if (contentLength) {
                // Convert the size to kilobytes for easier reading
                const sizeInKB = (Number(contentLength) / 1024).toFixed(2);
                return Number(sizeInKB);
            } else {
                throw new Error('Content-Length header is missing');
            }
        } catch (error) {
            console.error('Error getting image size:', error);
        }
    };

    /**
     * Get image mime type from url.
     * @param url Image url.
     * @returns Image mime type.
     */
    static getMimeTypeFromImageUrl = (url: string): string | null => {
        const urlObj = new URL(url);
        const extension = urlObj.pathname.split('.').pop() || ''; // Get the extension

        return MIME_TYPES[extension.toLowerCase()] || null;
    };

    /**
     * Checks if a base64 string represents a valid image.
     * @param {string} base64 - The base64 string to check.
     * @returns {Promise<boolean>} A promise that resolves to true if the base64 string is a valid image, and false otherwise.
     */
    static isValidBase64Image(base64: string): Promise<boolean> {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = base64;
        });
    }

    /**
     * Get image MIME type from URL by retrieving headers.
     * @param url - The URL of the image.
     * @returns - A Promise that resolves with the MIME type of the image, or null if MIME type cannot be determined.
     */
    static getMimeTypeFromImageHeaders = async (url: string): Promise<string | null> => {
        try {
            const response = await axios.head(url); // Send a HEAD request to retrieve headers
            const contentType = response.headers['content-type']; // Extract the content-type header from the response

            return contentType.split(';')[0]; // Extract and return MIME type from content-type header
        } catch (error) {
            // Log and return null in case of any errors
            console.error(MIME_TYPE_ERROR_MESSAGE, error);
            return null;
        }
    };

    /**
     * Convert image url to base64.
     * @param url Image url.
     * @param callback Callback function for when the conversion is completed.
     */
    static convertImageUrlToBase64 = async (url: string, callback: (base64: string | null | ArrayBuffer) => void) => {
        let mimeType = this.getMimeTypeFromImageUrl(url);

        // Get mime type from image headers if mimeType could not be found in the URL.
        if (!mimeType) {
            mimeType = await ImageFileService.getMimeTypeFromImageHeaders(url);
        }

        const type = mimeType ? mimeType : 'image/png'; // Default to PNG if the MIME type is not found.

        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            const blob = new Blob([xhr.response], { type: type });
            const reader = new FileReader();
            reader.onloadend = async () => {
                const results = reader.result as string; // Get the base64 string from the reader.
                const isValidBase64Image = await ImageFileService.isValidBase64Image(results); // Check if the base64 string is a valid image.

                isValidBase64Image ? callback(reader.result) : callback(null); // Return the base64 string if it is a valid image, otherwise return null.
            };
            reader.onerror = () => {
                callback(null); // Return null if there is an error reading the file.
            };
            reader.readAsDataURL(blob);
        };
        xhr.onerror = () => {
            callback(null); // Return null if there is an error loading the image.
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    };

    /**
     * Converts an array of asset URLs to base64 encoded strings.
     * @param assetUrls - An array of asset URLs to convert.
     * @returns A Promise that resolves to an array of base64 encoded strings, or null if the conversion fails.
     */
    static convertAssetUrlsToBase64 = async (assetUrls: (string | undefined)[]): Promise<(string | null)[]> => {
        const assetBase64Promises: Promise<string | null>[] = [];

        assetUrls.forEach((url) => {
            const base64Promise: Promise<string | null> = new Promise((resolve) => {
                try {
                    if (!url) {
                        resolve(null);
                        return;
                    }

                    this.convertImageUrlToBase64(url, (base64) => {
                        if (base64) {
                            resolve(base64 as string);
                        }

                        resolve(null);
                    });
                } catch (error) {
                    resolve(null);
                }
            });

            assetBase64Promises.push(base64Promise);
        });

        return await Promise.all(assetBase64Promises);
    };

    /**
     * Converts an array of base64-encoded image assets to their corresponding URLs by uploading them to the cloud.
     * @param base64Assets An array of base64-encoded image assets.
     * @returns A promise that resolves to an array of URLs corresponding to the uploaded assets.
     */
    static convertBase64AssetsToUrls = async (base64Assets: (string | undefined)[]) => {
        const assetUrlsPromises: Promise<string | undefined>[] = [];

        base64Assets.forEach((base64Asset) => {
            if (!base64Asset) {
                const emptyPromise = Promise.resolve(undefined); // Return undefined if the base64Asset is undefined or empty string.
                assetUrlsPromises.push(emptyPromise);
                return;
            }

            const assetUrlPromise = ImageFileService.uploadBase64(base64Asset);
            assetUrlsPromises.push(assetUrlPromise);
        });

        return await Promise.all(assetUrlsPromises); // Promise all uploads.
    };

    /**
     * Gets the aspect ratio of an image based on the given width and height.
     * @param width The width of the image.
     * @param height The height of the image.
     * @returns A string representing the aspect ratio of the image in the format "width:height".
     */
    static getImageRatio = (width: number, height: number) => {
        const gcd = (a: number, b: number): number => (b === 0 ? a : gcd(b, a % b));
        const divisor = gcd(width, height);

        return `${width / divisor}:${height / divisor}`;
    };

    /**
     * Calculates the aspect ratio of an image based on a string representation of the ratio.
     * @param aspectRatio - A string representation of the aspect ratio in the format "width:height".
     * @returns The aspect ratio as a decimal number.
     */
    static calculateImageRatio = (aspectRatio: string) => {
        if (!aspectRatio || aspectRatio === '0') {
            return 0;
        }

        const arr = aspectRatio.split(':');
        return parseInt(arr[0]) / parseInt(arr[1]);
    };

    /**
     * Calculates the aspect ratio of an image based on its resolution.
     * @param width The width of the image.
     * @param height The height of the image.
     * @returns The aspect ratio of the image.
     */
    static calculateImageRatioByResolution = (width: number, height: number) => {
        return width / height;
    };

    /**
     * Fetches the image content from the given URL and returns its size in KB.
     * @param imageUrl The URL of the image to fetch.
     * @returns A Promise that resolves to the size of the image in KB.
     * @throws An error if the image fails to fetch.
     */
    static getImageSizeInKBFromUrl = async (imageUrl: string) => {
        try {
            // Fetch the image content
            const response = await fetch(imageUrl);
            const blob = await response.blob();

            // Calculate the size in KB
            return blob.size / 1024;
        } catch (error) {
            console.log('Error:', error);
        }
    };

    /**
     * Calculates the size of a base64-encoded file in kilobytes (KB).
     * @param base64 - The base64-encoded file.
     * @returns The size of the file in kilobytes (KB).
     */
    static getFileSizeInKBFromBase64(base64: string): number {
        // Remove the data URI prefix (e.g., 'data:image/png;base64,')
        const base64WithoutPrefix = base64.replace(/^data:[a-z]+\/[a-z]+;base64,/, '');

        // Calculate the size in bytes
        const byteSize = (base64WithoutPrefix.length * 3) / 4;

        // Convert to kilobytes (KB)
        return byteSize / 1024;
    }

    /**
     * Calculates the size of a file in kilobytes (KB) from a URL.
     * @param url - The URL of the file.
     * @returns The size of the file in kilobytes (KB).
     */
    static async getFileSizeInKBFromUrl(url: string): Promise<number> {
        const response = await fetch(url);
        const blob = await response.blob();

        return this.convertBytesToKiloBytes(blob.size);
    }

    /**
     * Converts bytes to kilobytes.
     * @param bytes The number of bytes to convert.
     * @returns The number of kilobytes.
     */
    static convertBytesToKiloBytes = (bytes: number) => {
        return bytes / 1024;
    };

    /**
     * Converts kilobytes to bytes.
     * @param kilobytes The number of kilobytes to convert to bytes.
     * @returns The number of bytes.
     */
    static convertKiloBytesToBytes = (kilobytes: number) => {
        return kilobytes * 1024;
    };

    /**
     * Returns the image format (e.g. "png", "jpeg", "gif") of a base64-encoded image string.
     * @param base64String The base64-encoded image string to check.
     * @returns The image format if the input string is a valid base64-encoded image, otherwise null.
     */
    static getImageFormatFromBase64(base64String: string): string | null {
        // Check if the input string is a valid base64-encoded image
        const regex = /^data:image\/(\w+);base64,/;
        const match = base64String.match(regex);

        if (match) {
            // The match array will contain the image format at index 1
            return match[1];
        }

        // If the input is not a valid base64-encoded image, return null
        return null;
    }

    /**
     * Retrieves the dimensions of an image from a URL.
     * @param url The URL of the image.
     * @returns A promise that resolves to an object containing the width and height of the image.
     * @throws An error if there is an error loading the image.
     */
    static loadImage(url: string): Promise<AssetDimensions | Event | string> {
        return new Promise((res, rej) => {
            const img = new Image();
            img.onerror = (error) => rej(error);
            img.onload = () =>
                res({
                    width: img.width,
                    height: img.height
                });
            img.src = url;
        });
    }

    /**
     * Retrieves the dimensions of an image from a Base64 string.
     * @param base64String The Base64 string representation of the image.
     * @returns A promise that resolves to an object containing the width and height of the image.
     * @throws An error if there is an error loading the image or if the Base64 string is invalid.
     */
    static getBase64ImageDimensions(base64String: string): Promise<AssetDimensions> {
        return new Promise((resolve, reject) => {
            const img = new Image();

            img.onload = function () {
                const dimensions = {
                    width: img.width,
                    height: img.height
                };

                // Resolve the promise with the dimensions
                resolve(dimensions);
            };

            img.onerror = function () {
                // Reject the promise if there is an error (e.g., invalid Base64 string or non-existent image)
                reject(new Error('Error getting image dimensions.'));
            };

            // Set the source of the image to the Base64 string
            img.src = base64String;
        });
    }
}

export default ImageFileService;

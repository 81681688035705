import * as Sentry from '@sentry/react';
import axios from 'axios';
import Request from 'components/data/Request';
import User from 'components/data/User';
import { TemplateCopy, TemplateSizeResponse } from './template.response';

class TemplateService {
    /**
     * Copies a template with specific id
     * @param templateId of the template.
     * @returns Success status.
     */
    static async tempateCopy(templateId: string): Promise<TemplateCopy | undefined> {
        try {
            const response = await Request.post('templates/copy', {
                id: templateId
            });
            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);

            if (error instanceof Error) {
                throw error;
            }
        }
    }

    /**
     * Get the template size.
     * @param templateId - The id of the template.
     * @returns The Template Designer template data.
     */
    static async getTemplateSize(template: unknown): Promise<TemplateSizeResponse | undefined> {
        try {
            const response = await axios.post(
                `${process.env.APP_MEDIA_URL}media/templateCalculator`,
                { templateData: template, url: process.env.TEMPLATES_ZIP_URL },
                { headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` } }
            );
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
        }
    }
}

export { TemplateService };

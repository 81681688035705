export default {
    // eslint-disable-line

    LOGIN_PENDING: 'AUTH_LOGIN_PENDING',
    LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    LOGIN_ERROR: 'AUTH_LOGIN_ERROR',
    LOGIN_ANIMATION: 'AUTH_LOGIN_ANIMATION',
    LOGIN_ANIMATION_END: 'AUTH_LOGIN_ANIMATION_END',

    TOKEN_LOGIN_PENDING: 'AUTH_TOKEN_LOGIN_PENDING',
    TOKEN_LOGIN_SUCCESS: 'AUTH_TOKEN_LOGIN_SUCCESS',
    TOKEN_LOGIN_ERROR: 'AUTH_TOKEN_LOGIN_ERROR',
    TOKEN_REFRESH: 'AUTH_TOKEN_REFRESH',

    LOGOUT_PENDING: 'AUTH_LOGOUT_PENDING',
    LOGOUT_SUCCESS: 'AUTH_LOGOUT_SUCCESS',
    LOGOUT_ERROR: 'AUTH_LOGOUT_ERROR',

    PREVIEW_LOGIN_SUCCESS: 'AUTH_PREVIEW_LOGIN_SUCCESS',

    USER_PROFILE_SUCCESS: 'AUTH_USER_PROFILE_SUCCESS',
    USER_PROFILE_ERROR: 'AUTH_USER_PROFILE_ERROR',
    USER_PROFILE_SET: 'AUTH_USER_PROFILE_SET'
};

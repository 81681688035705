import axios, { CancelToken } from 'axios';
import ImageFileService from 'services/image-file/image.service';
import ImageExportHelper from 'components/assets/AssetEditorControllers/components/ImageCompressor/helpers/image-export-helper';
import {
    ObjectRemoverProps,
    InpaintProps,
    PaintByExampleProps,
    ProcessProps,
    OutpaintProps
} from 'components/assets/AssetEditorControllers/interfaces/AssetEditorControllerProps';
import User from 'components/data/User';

/**
 * Represents the response object returned by the `removeObjectsFromImage` method of the `ImageModifierService` class.
 */
interface Response {
    /* The URL of the modified image. */
    url: string;
}

interface CancelResponse {
    text: string;
}

/**
 * Provides methods for modifying images, such as removing objects and backgrounds.
 */
class ImageModifierService {
    /**
     * Removes objects from an image using a mask image.
     * @param imageFileUrl The URL of the image file to modify.
     * @param maskFileUrl The URL of the mask file to use for object removal.
     */
    static removeObjectFromImage = async ({ imageFileUrl, maskFileUrl, abortController }: ObjectRemoverProps) => {
        const response = await axios.post<Response>(
            process.env.APP_MEDIA_URL + 'media/objectRemover',
            { imageFileUrl: imageFileUrl, maskFileUrl: maskFileUrl },
            {
                headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` },
                signal: abortController?.current?.signal
            }
        );

        return response.data.url;
    };

    /**
     * Inpaints an image using a mask image and a prompt
     * @param imageFileUrl The URL of the image file to modify.
     * @param maskFileUrl The URL of the mask file to use for object removal.
     * @param prompt The prompt to use for inpainting.
     */
    static inpaint = async ({ imageFileUrl, maskFileUrl, prompt, abortController }: InpaintProps) => {
        const response = await axios.post<Response>(
            process.env.APP_MEDIA_URL + 'media/inpaint',
            { imageFileUrl, maskFileUrl, prompt },
            {
                headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` },
                signal: abortController?.current?.signal
            }
        );

        return response.data.url;
    };

    /**
     * Outpaints an image
     */
    static outpaint = async ({ imageFileUrl, outpaintData, abortController }: OutpaintProps) => {
        const { outpaintRequestData, cropData, imagePosition } = outpaintData;
        const token = User.get('mediaServicesApiToken');

        try {
            // Create an HTML image element
            const imageElement = new Image();

            // Set crossOrigin attribute to allow CORS
            imageElement.crossOrigin = 'Anonymous';
            imageElement.src = imageFileUrl;

            // Create a promise that resolves when the image loads
            const imageLoadedPromise = new Promise((resolve, reject) => {
                imageElement.onload = resolve;
                imageElement.onerror = reject;
            });

            // Wait for the image to load
            await imageLoadedPromise;

            const resizedImageElement = await ImageExportHelper.resizeImage(imageElement, imagePosition.width, imagePosition.height, 1);
            const resizedImageURL = await ImageFileService.uploadBase64(resizedImageElement.src);

            const croppedImageRes = await axios.post<Response>(
                process.env.APP_MEDIA_URL + 'media/imageResizer',
                { url: resizedImageURL, ...cropData, targetExtension: '.png' },
                {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: abortController?.current?.signal
                }
            );

            if (croppedImageRes) {
                const response = await axios.post<Response>(
                    process.env.APP_MEDIA_URL + 'media/uncrop',
                    { url: croppedImageRes.data.url, ...outpaintRequestData, seed: Math.floor(Math.random() * 100000) },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                        signal: abortController?.current?.signal
                    }
                );
                // Fixing the URL and returning it
                return response.data.url.replace('https://storage-acceptance.bycape.io', 'https://storage-acceptance.bycape.io/');
            }
        } catch (err) {
            console.error('====================================');
            console.error(err);
            console.error('====================================');
        }
    };

    /**
     * Inpaints and image using a mask and an example image
     * @param imageFileUrl The URL of the image file to modify.
     * @param maskFileUrl The URL of the mask file to use for object removal.
     * @param exampleUrl and example image of the object to inpaint
     */
    static paintByExample = async ({ imageFileUrl, maskFileUrl, exampleFileUrl, abortController }: PaintByExampleProps) => {
        const response = await axios.post<Response>(
            process.env.APP_MEDIA_URL + 'ai/paintByExample',
            { url: imageFileUrl, maskUrl: maskFileUrl, exampleUrl: exampleFileUrl },
            {
                headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` },
                signal: abortController?.current?.signal
            }
        );

        return response.data.url;
    };

    /**
     * Remove the background from an image.
     */
    static removeBackground = async ({ imageFileUrl, abortController }: ProcessProps) => {
        const response = await axios.post<Response>(
            process.env.APP_MEDIA_URL + 'media/backgroundRemover',
            { url: imageFileUrl },
            {
                headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` },
                signal: abortController?.current?.signal
            }
        );

        return response.data.url;
    };

    /**
     * Compresses an image using the specified compression level.
     * @param imageUrl The URL of the image to compress.
     * @param compressionLevel The level of compression to apply to the image.
     * @param cancelToken An optional cancel token that can be used to cancel the request.
     * @returns A Promise that resolves with the URL of the compressed image.
     * @throws An error if the compression fails.
     */
    static compressImage = async (imageUrl: string, compressionLevel?: number, cancelToken?: CancelToken) => {
        try {
            const response = await axios.post(
                process.env.APP_MEDIA_URL + 'media/compressImage',
                { filePath: imageUrl, compressionLevel },
                {
                    headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` },
                    cancelToken: cancelToken
                }
            );

            return response.data.url as string;
        } catch (error) {
            if (!axios.isCancel(error)) {
                console.log('Error:', error);
            }
            throw error;
        }
    };

    static handleImageModificationError = (error: any) => {
        if (error.code === 'ERR_CANCELED') {
            axios.post<CancelResponse>(
                process.env.APP_MEDIA_URL + 'ai/cancel',
                {},
                {
                    headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` }
                }
            );
        }

        if (error.code !== 'ERR_CANCELED') throw error;
    };
}

export default ImageModifierService;

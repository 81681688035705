import axios from 'axios';
import * as Sentry from '@sentry/react';

/**
 * Upload a file to a url.
 * @param url - The url where the file is going to be uploaded.
 * @param file - The file to be uploaded. Provided from file input.
 * @param options.withCredentials - Does it need credentials (default false).
 * @param options.updateUploadProgress - Function to pass the upload progress into.
 */
const uploadFile = async (
    url: string,
    file: File,
    options?: { withCredentials?: boolean; updateUploadProgress: (progress: number) => void }
): Promise<void> => {
    try {
        await axios.put(url, file, {
            headers: { 'Content-Type': file.type },
            withCredentials: options?.withCredentials || false,
            onUploadProgress: (progressEvent) => {
                if (!options?.updateUploadProgress) return;
                options.updateUploadProgress(Math.round((progressEvent.loaded / (progressEvent.total || 0)) * 100));
            }
        });
    } catch (error: unknown) {
        Sentry.captureException(error);
    }
};

export default uploadFile;

import { merge } from 'lodash';
import { produce, current, setAutoFreeze } from 'immer';
import EditorHistory from 'components/editor-data/EditorHistory';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import types from './types';

const defaultState = {
    id: 0,
    type: '',
    loading: true,
    data: {},
    language: 'EN',
    flight: '',
    origin: 'GEN',
    lastSaved: false,
    versionNr: 0,
    localScope: {}
};

export default (state = defaultState, action) => { // eslint-disable-line
    setAutoFreeze(false);

    switch (action.type) {
        // Data was loaded, set state
        case types.LOADING:
            return { ...defaultState, loading: true, error: false };

        // Data was loaded, set state
        case types.LOAD_SUCCESS:
            // Set initial language after starting
            let language = state.language;
            if (action.payload.data.settings && action.payload.data.settings.languages) {
                language = Object.keys(action.payload.data.settings.languages)[0];
            }

            // Set initial flight
            let flight = state.flight;
            if (action.payload.data.settings && action.payload.data.settings.flights) {
                flight = action.payload.data.settings.flights[0].id;
            }

            // In case the data has the incorrect type (due to PHP), we convert it to an object
            if (Array.isArray(action.payload.data)) {
                action.payload.data = {};
            }

            if (action.payload.updated) {
                return {
                    ...state,
                    language: language,
                    flight: flight,
                    lastSaved: action.payload.updated.date,
                    type: action.type,
                    ...action.payload
                };
            } else {
                return {
                    ...state,
                    language: language,
                    flight: flight,
                    type: action.type,
                    ...action.payload
                };
            }

        // There was an error loading
        case types.LOAD_ERROR:
            return { ...state, ...action.payload };

        // Change the language that is currently being edited
        case types.CHANGE_LANGUAGE:
            return { ...state, language: action.payload.language };

        // Import a data set and merge with existing data
        case types.IMPORT_DATA:
            return { ...state, data: merge(state.data, action.payload.data) };

        // Change anything in the state
        case types.CHANGE_STATE:
            const newState = { ...state, ...action.payload };
            return newState;
        // Reset the store
        case types.RESET:
            return {
                ...defaultState,
                data: {},
                id: 0,
                type: ''
            };
        // Reset the localscope
        case types.LOCALSCOPE_RESET:
            const localScopeData = state.localScope;
            const scopeId = action.payload.localScope;
            delete localScopeData[scopeId];

            return {
                ...state,
                localScope: localScopeData
            };
        // Reset the localscope
        case types.LOCALSCOPE_SET:
            const localScopeDataFull = state.localScope;
            const localScopeId = action.payload.localScope;
            const defaultDataSet = action.payload.data;

            localScopeDataFull[localScopeId] = defaultDataSet;

            return {
                ...state,
                localScope: localScopeDataFull
            };
        // Change anything in the state
        case types.SAVE:
            var d = new Date();
            var time = d.getTime();

            // We've loaded a new view, do not overwrite
            if (action.payload.id != state.id) {
                return state;
            }
            return { ...state, lastSaved: time, ...action.payload };
        // Change a value within the campaign
        case types.CHANGE_MODEL:
            return produce(state, (draft) => {
                // Initialize vars
                let data;
                let activeDraft;
                let activeObject;

                // We have a local scope, save to local scope project
                if (action.payload.localScope) {
                    data = current(draft).localScope[action.payload.localScope];

                    // Initialize local scope
                    if (!current(draft).localScope[action.payload.localScope]) {
                        draft.localScope[action.payload.localScope] = {};
                        data = {};
                    }
                    activeDraft = draft.localScope[action.payload.localScope];
                }
                // We have the global campaign object
                else {
                    data = current(draft).data;
                    activeDraft = draft.data;
                }
                activeObject = data;

                // Loop through model to set values. E.g. website.copy.[[language]].title
                let model = action.payload.model;
                if (!model) {
                    SnackbarUtils.error('Model not set for item');
                    return;
                }

                model = model.replace('[[language]]', state.language);
                if (state.origin) {
                    model = model.replace('[[origin]]', state.origin);
                }
                if (state.flight) {
                    model = model.replace('[[flight]]', state.flight);
                }

                const modelParts = model.split('.');

                // Loop through items and write value
                modelParts.forEach((part, i) => {
                    // Create an empty object if needed
                    if (typeof activeObject[part] !== 'object' || activeObject[part] === null || Array.isArray(activeObject[part])) {
                        activeDraft[part] = {};

                        // Set the activeObject to an empty object, but only if it's not the last part (sometimes we need empty arrays in the state)
                        // For example, otherwise the undo function will set a previous [] to a {}, which crashes a lot of components
                        if (modelParts.length - 1 != i) activeObject[part] = {};
                    }

                    // This is the last part, we've reached the correct path. Set the new value.
                    if (modelParts.length - 1 == i) {
                        activeDraft[part] = action.payload.value;
                    }

                    activeDraft = activeDraft[part];
                    activeObject = activeObject[part];
                });

                // Create new state
                const resultState = { ...state, data: data };
                EditorHistory.addItem(resultState);
            });
        default:
            return state;
    }
};

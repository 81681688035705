export default { // eslint-disable-line
    CLEAR_FILTER_SELECTION: 'CONTENTSPACE_CLEAR_FILTER_SELECTION',
    TOGGLE_FILTER_SELECTION: 'CONTENTSPACE_TOGGLE_FILTER_SELECTION',
    CLEAR_ALL_FILTER_SELECTION: 'CONTENTSPACE_CLEAR_ALL_FILTER_SELECTION',
    TOGGLE_ASSET_SELECTION: 'CONTENTSPACE_TOGGLE_ASSET_SELECTION',
    SET_ASSET_SELECTION: 'CONTENTSPACE_SET_ASSET_SELECTION',
    ADD_ASSETS: 'CONTENTSPACE_ADD_ASSETS',
    DELETE_ASSETS: 'CONTENTSPACE_DELETE_ASSETS',
    ADD_ASSET_METADATA: 'CONTENTSPACE_ADD_ASSET_METADATA',
    ADD_ASSETS_METADATA_BATCH: 'CONTENTSPACE_ADD_ASSET_METADATA_BATCH',
    FETCH_ALL_COLLECTIONS: 'CONTENTSPACE_FETCH_ALL_COLLECTIONS',
    FETCH_ASSETS: 'CONTENTSPACE_FETCH_ASSETS',
    FETCH_CONCEPTS: 'CONTENTSPACE_FETCH_CONCEPTS',
    FETCH_MORE_ASSETS: 'CONTENTSPACE_FETCH_MORE_ASSETS',
    FETCH_POPULAR_FILTERS: 'CONTENTSPACE_FETCH_POPULAR_FILTERS',
    FETCH_COLLECTIONS: 'CONTENTSPACE_FETCH_COLLECTIONS',
    FETCH_MORE_COLLECTIONS: 'CONTENTSPACE_FETCH_MORE_COLLECTIONS',
    ADD_ASSET_TO_COLLECTION: 'CONTENTSPACE_ADD_ASSET_TO_COLLECTION',
    ADD_COLLECTION_TO_COLLECTION: 'CONTENTSPACE_ADD_COLLECTION_TO_COLLECTION',
    FETCH_COLLECTION_ITEMS: 'CONTENTSPACE_FETCH_COLLECTION_ITEMS',
    FETCH_MORE_COLLECTION_ITEMS: 'CONTENTSPACE_FETCH_MORE_COLLECTION_ITEMS',
    NEW_COLLECTION: 'CONTENTSPACE_NEW_COLLECTION',
    DELETE_COLLECTION_ITEM: 'CONTENTSPACE_DELETE_COLLECTION_ITEM',
    DELETE_COLLECTION: 'CONTENTSPACE_DELETE_COLLECTION',
    ADD_COLLECTION_METADATA: 'CONTENTSPACE_ADD_COLLECTION_METADATA',
    FETCH_TOKEN: 'CONTENTSPACE_FETCH_TOKEN',
    UPDATE_SEARCH_QUERY: 'CONTENTSPACE_UPDATE_SEARCH_QUERY',
    SET_RESOURCES: 'CONTENTSPACE_CONTENTSPACE_SET_RESOURCES',
    SET_LOCATION_VIEW: 'CONTENTSPACE_SET_LOCATION_VIEW',
    FETCH_ASSET_COUNT: 'CONTENTSPACE_FETCH_ASSET_COUNT',
    SET_COLLECTION_REFERENCE_ID: 'CONTENTSPACE_SET_COLLECTION_REFERENCE_ID',
    SET_ASSET_SAVED: 'CONTENTSPACE_SET_ASSET_SAVED',
    SET_ASSETS_BATCH_SAVED: 'CONTENTSPACE_SET_ASSETS_BATCH_SAVED',
    SET_ASSETS_FETCHED: 'CONTENTSPACE_SET_ASSETS_FETCHED',
    SET_COLLECTIONS_FETCHED: 'CONTENTSPACE_SET_COLLECTIONS_FETCHED',
    SET_ASSET_SORTING: 'CONTENTSPACE_SET_ASSET_SORTING',
    SET_CONCEPTS: 'CONTENTSPACE_SET_CONCEPTS',
    SET_FILTER_EXPIRED: 'CONTENTSPACE_SET_FILTER_EXPIRED',
    SET_FILTERS_FETCHED: 'CONTENTSPACE_SET_FILTERS_FETCHED',
    SET_ALL_FILTER_SELECTION: 'CONTENTSPACE_SET_ALL_FILTER_SELECTION',
    EMPTY_BIN: 'CONTENTSPACE_EMPTY_BIN',
    UNBIN_ASSETS: 'CONTENTSPACE_UNBIN_ASSETS'
};

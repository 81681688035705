import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, combineReducers, legacy_createStore as createStore } from 'redux';
import thunk from 'redux-thunk';

import authReducer from './reducers/Auth/reducer';
import setupReducer from './reducers/Setup/reducer';
import basedataReducer from './reducers/Basedata/reducer';
import editorReducer from './reducers/Editor/reducer';
import resourcesReducer from './reducers/Resources/reducer';
import contentspaceReducer from './reducers/ContentSpace/reducer';
import componentStoreReducer from './reducers/ComponentStore/reducer';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory()
});

export const store = createStore(
    combineReducers({
        router: routerReducer,
        auth: authReducer,
        basedata: basedataReducer,
        editor: editorReducer,
        resources: resourcesReducer,
        contentspace: contentspaceReducer,
        componentStore: componentStoreReducer,
        setup: setupReducer
    }),
    composeWithDevTools(applyMiddleware(routerMiddleware, thunk))
);

export const history = createReduxHistory(store);

export default store;

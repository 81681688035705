import { v4 as randomUUID } from 'uuid';
import { Message } from './Message';

const PING_MESSAGE_INTERVAL = 5000;

export class MessageClient {
    private static instance: MessageClient;
    private url = '';
    private key = '';
    private socketConnection: WebSocket | undefined;
    private pingTimer: NodeJS.Timeout | undefined;

    private topicListeners: Map<string, Map<string, MessageListener>> = new Map();
    private messageListeners: Map<string, Map<string, MessageListener>> = new Map();
    private broadcastListeners: Map<string, MessageListener> = new Map();
    private lastPing = 0;

    private connected = false;
    private disableReconnect = false;

    private constructor() {
        // Private constructor to prevent instantiation outside of the class
    }

    /**
     * Get an instance of the message class
     * @returns An instance of the MessageClient
     */
    public static getInstance(): MessageClient {
        if (!MessageClient.instance) {
            MessageClient.instance = new MessageClient();
        }

        return MessageClient.instance;
    }

    /**
     * Whether the client is connected to the server or not
     * @returns
     */
    public isConnected(): boolean {
        return this.connected;
    }

    /**
     * Set the JWT key for the socket connection
     * @param key
     */
    public setConnectionInfo(key: string, url: string) {
        this.key = key;
        this.url = url;
        this.connect();
    }

    /**
     * Disconnect from the socket server, should be used when a user logs out
     */
    public disconnect() {
        if (this.socketConnection) {
            this.connected = false;
            this.socketConnection?.close();
            clearInterval(this.pingTimer);
        }

        this.key = '';
        this.url = '';
    }

    /**
     * Connect to the socket server
     */
    private connect() {
        if (this.key === '' || this.url === '') {
            console.error('No notification connection information set. Waiting for information...');
        }

        // Close existing connections
        if (this.socketConnection) {
            this.connected = false;
            this.socketConnection.close();
            clearInterval(this.pingTimer);
        }

        // Create a new connection
        this.socketConnection = new WebSocket(`${this.url.replace('http', 'ws').replace('https', 'wss')}?token=${this.key}`);

        // When the connection is succefully opened, we can start sending ping messages to check whether the connection is still alive
        this.socketConnection.onopen = () => {
            this.connected = true;

            // We are connected, so we can start checking for ping messages to check whether the connection is still alive
            this.lastPing = new Date().getTime();
            this.pingTimer = setInterval(() => {
                if (this.lastPing < new Date().getTime() - PING_MESSAGE_INTERVAL * 2) {
                    console.error('No ping received from server, reconnecting...');

                    this.socketConnection?.close();
                    return;
                }
            }, PING_MESSAGE_INTERVAL);

            // If we have any subscriptions, we should resubscribe to them
            this.topicListeners.forEach((listeners, topic) => {
                this.socketConnection?.send(
                    JSON.stringify({
                        path: 'subscribe',
                        topic: topic
                    })
                );
            });
        };

        // When the connection is closed, we will try to reconnect, unless the connection was closed because of an invalid JWT key
        // Most of this code will probably not function, since websocket close codes are obfuscated by browsers for security purposes🤦‍♂️
        this.socketConnection.onclose = (event) => {
            this.connected = false;

            if (this.disableReconnect) {
                console.error('Reconnect disabled because of an error, waiting 5 seconds before trying again...');
            } else if (event.code === 4001) {
                // We will not reconnect, but wait until other credentials are supplied
                console.error('Invalid JWT key during websocket connect, waiting for new credentials...');
            } else {
                switch (event.code) {
                    case 1007:
                        console.error('We have sent an invalid message to the server, reconnecting...');
                        break;
                    case 4002:
                        console.error('Automatic connection reset, reconnecting...');
                        break;
                    default:
                        console.error('Socket closed unexpectedly, reconnecting...');
                }

                this.connect();
            }
        };

        // When a message is received, we will will send it to the right listeners
        this.socketConnection.onmessage = (event) => {
            // Register ping messages
            if (event.data.toString() === 'ping') {
                this.lastPing = new Date().getTime();
                return;
            }

            const message: Message = JSON.parse(event.data.toString());

            if (message.topic) {
                // Find exact listeners
                this.topicListeners.get(message.topic)?.forEach((listener) => {
                    listener(message);
                });

                // Find wildcard listeners
                const topicComponents = message.topic.split('/');
                for (let i = 0; i < topicComponents.length; i++) {
                    const wildcardTopic = topicComponents.slice(0, i).join('/') + '/*';

                    this.topicListeners.get(wildcardTopic)?.forEach((listener) => {
                        listener(message);
                    });
                }
            } else {
                if (message.path) {
                    if (this.messageListeners.has(message.path)) {
                        this.messageListeners.get(message.path)?.forEach((listener) => listener(message));
                    }
                }
                this.broadcastListeners.forEach((listener) => listener(message));
            }
        };

        /**
         * handle any errors that occur
         */
        this.socketConnection.onerror = async () => {
            this.disableReconnect = true;
            await new Promise((resolve) => setTimeout(resolve, 5000));
            this.disableReconnect = false;
            this.connect();
        };
    }

    /**
     * Subscribe to a topic, and define a callback function that will be called when a message is received
     * This function will block until a valid connection the the socket server is acquired.
     * @param topic
     * @param handler
     * @returns A subscription id that can be used to unsubscribe from the topic
     */
    public subscribeToTopic(topic: string, handler: MessageListener): string {
        const uuid = randomUUID();
        if (this.connected && this.socketConnection) {
            // Check if the socket is already subscribed to this topic
            if (!this.topicListeners.get(topic) || this.topicListeners.get(topic)!.size < 1) {
                this.socketConnection?.send(
                    JSON.stringify({
                        path: 'subscribe',
                        topic: topic
                    })
                );
            }
        } else {
            //console.log('Not connected yet, will subscribe when connected succesfully.');
        }

        if (!this.topicListeners.has(topic)) {
            this.topicListeners.set(topic, new Map());
        }

        this.topicListeners.get(topic)?.set(uuid, handler);

        return uuid;
    }

    /**
     * Unsubscribe from a topic
     * @param topic The topic to unsubscribe from
     * @param id The id of the subscription
     */
    public unsubscribeFromTopic(topic: string, id: string) {
        if (this.topicListeners.has(topic)) {
            this.topicListeners.get(topic)?.delete(id);
        }

        if (this.connected && this.socketConnection) {
            // If the topic does not exist, or there are no listeners left, we can unsubscribe from the topic
            if (!this.topicListeners.get(topic) || this.topicListeners.get(topic)!.size < 1) {
                this.socketConnection?.send(
                    JSON.stringify({
                        path: 'unsubscribe',
                        topic: topic
                    })
                );
            }
        } else {
            console.error('Unable to unsubscribe from topic, not connected to server');
        }
    }

    /**
     * Subscribe to a specific message type (path), and define a callback function that will be called when a message is received
     * @param messagePath the path of the message to subscribe to
     * @param handler the callback function that will be called when a message is received
     * @returns a subscription id that can be used to unsubscribe from the message
     */
    public subscribeToMessage(messagePath: string, handler: MessageListener): string {
        const uuid = randomUUID();

        if (!this.messageListeners.has(messagePath)) {
            this.messageListeners.set(messagePath, new Map());
        }

        this.messageListeners.get(messagePath)?.set(uuid, handler);

        return uuid;
    }

    /**
     * Unsubscribe from a specific message type (path)
     * @param messagePath the path of the message to unsubscribe from
     * @param id the id of the subscription
     */
    public unsubscribeFromMessage(messagePath: string, id: string) {
        if (this.messageListeners.has(messagePath)) {
            this.messageListeners.get(messagePath)?.delete(id);
        }
    }

    /**
     * Subscribe to all messages, and define a callback function that will be called when a message is received
     * @param handler the callback function that will be called when a message is received
     * @returns a subscription id that can be used to unsubscribe
     */
    public subscribe(handler: MessageListener): string {
        const uuid = randomUUID();
        this.broadcastListeners.set(uuid, handler);

        return uuid;
    }

    /**
     * Unsubscribe from all messages by your subscription id
     * @param id the id of the subscription
     */
    public unsubscribe(id: string) {
        this.broadcastListeners.delete(id);
    }
}

export type MessageListener = (message: Message) => void;

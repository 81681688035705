import types from './types.js';

export default (state = { // eslint-disable-line
    users:[]
}, action) => {
    let result = {};
    switch (action.type) {
        case types.LOADDATA_SUCCESS:

            result = {
                    ...state,
                    ...action.payload
            };
            break;
        default:
            result = state;
    }

    return result;
};

import Request from 'components/data/Request';
import types from './types';

/**
 * Load data completed
 * @param {*} id ID o the campaign
 * @param {*} data The data to load
 */
function loadData(id, data) {
    return (dispatch) => {
        dispatch({
            type: types.LOAD_SUCCESS,
            payload: {
                id: id,
                loading: false,
                error: false,
                ...data
            }
        });
    };
}

/**
 * Error when loading the campaign
 * @param {*} id id of the campaign
 * @param {*} data
 */
function loadError(id, data) {
    return (dispatch) => {
        dispatch({
            type: types.LOAD_ERROR,
            payload: {
                id: id,
                loading: false,
                error: data.message
            }
        });
    };
}

/**
 * Loads data from the preview url
 * @param {*} campaignId The id of the campaign
 * @param {*} token The preview token generated when creating the campaign
 * @param {*} language The language to load
 */
function loadDataFromPreview(campaignId, token, language) {
    return (dispatch) => {
        dispatch({
            type: types.LOADING,
            payload: {
                id: campaignId,
                loading: true,
                error: false
            }
        });

        Request.post('previews/getCampaign', { campaignId: campaignId, token: token }).then(
            (result) => {
                dispatch({
                    type: types.LOAD_SUCCESS,
                    payload: {
                        id: campaignId,
                        loading: false,
                        market: result.data.previewSetup.market,
                        language: language,
                        ...result.data
                    }
                });
            },
            (result) => {
                dispatch({
                    type: types.LOAD_ERROR,
                    payload: {
                        id: campaignId,
                        loading: false,
                        error: result.message
                    }
                });
            }
        );
    };
}

/**
 * Change the current active language
 * @param {*} language The language to set
 */
function changeLanguage(language) {
    return (dispatch) => {
        dispatch({
            type: types.CHANGE_LANGUAGE,
            payload: {
                language: language
            }
        });
    };
}

/**
 * Save the data
 * @param {*} data data of the campaign
 */
function save(data) {
    if (!data.success) {
        return;
    }

    return (dispatch) => {
        dispatch({
            type: types.SAVE,
            payload: data.data
        });
    };
}

/**
 * Reset the current campaign
 */
function reset() {
    return (dispatch) => {
        dispatch({
            type: types.RESET,
            payload: {}
        });
    };
}

export default {
    // eslint-disable-line
    loadData,
    loadError,
    changeLanguage,
    save,
    reset,
    loadDataFromPreview
};

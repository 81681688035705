import Axios from 'axios';
import Setup from 'components/data/Setup';
import AssetHelpers from 'components/data/AssetHelpers';
import ViewState from 'components/data/ViewState';
import Translation from 'components/data/Translation';
import types from './types';

let token = '';
let lastAssetFilterSearch = null;
let lastCollectionFilterSearch = null;
let lastCollectionItemsFilterSearch = null;

/**
 * Toggle a filter selection and reload the affected filters.
 * @param {object} filter
 * @param {string} filterKey
 */
export function toggleFilterSelection(filter, filterKey) {
    return async (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_FILTER_SELECTION,
            payload: {
                filter: filter,
                filterKey: filterKey
            }
        });

        if (filter.filterType === 'collections') {
            await getCollectionSearchFilters(getState(), dispatch);
        } else {
            const { locationView } = getState().contentspace;
            if (locationView === 'collectionItems') {
                await getCollectionItemsSearchFilters(getState(), dispatch);
            } else {
                await getAssetSearchFilters(getState(), dispatch);
            }
        }
    };
}

/**
 * Clear the selection of one filter, the reload the filters
 * @param {object} filter
 * @param {string} filterType
 */
export function clearFilterSelection(filter, filterType) {
    return async (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_FILTER_SELECTION,
            payload: filter
        });

        if (filterType === 'collections') {
            await getCollectionSearchFilters(getState(), dispatch);
        } else {
            const { locationView } = getState().contentspace;
            if (locationView === 'collectionItems') {
                await getCollectionItemsSearchFilters(getState(), dispatch);
            } else {
                await getAssetSearchFilters(getState(), dispatch);
            }
        }
    };
}

export function setAllFilterSelection(filters, filterType, refresh) {
    return async (dispatch, getState) => {
        dispatch({
            type: types.SET_ALL_FILTER_SELECTION,
            payload: { filters, filterType }
        });

        if (filterType === 'collections') {
            await getCollectionSearchFilters(getState(), dispatch);
        } else {
            const { locationView } = getState().contentspace;
            if (locationView === 'collectionItems') {
                await getCollectionItemsSearchFilters(getState(), dispatch, refresh);
            } else {
                await getAssetSearchFilters(getState(), dispatch, refresh);
            }
        }
    };
}

/**
 * Clear all filters of a certain filtertype. The reload the filters.
 * @param {string} filterType
 * @param {boolean} getSearchFilters
 * @returns
 */
export function clearAllFilterSelection(filterType, getSearchFilters = true) {
    return async (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_ALL_FILTER_SELECTION,
            payload: filterType
        });

        dispatch({
            type: types.UPDATE_SEARCH_QUERY,
            payload: { query: '' }
        });

        if (getSearchFilters) {
            if (filterType === 'collections') {
                await getCollectionSearchFilters(getState(), dispatch);
            } else {
                const { locationView } = getState().contentspace;
                if (locationView === 'collectionItems') {
                    await getCollectionItemsSearchFilters(getState(), dispatch);
                } else {
                    await getAssetSearchFilters(getState(), dispatch);
                }
            }
        }
    };
}

/**
 * Toggle the presence of an asset in the selected assets in Redux.
 * @param {string} asset
 */
export function toggleAssetSelection(asset) {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_ASSET_SELECTION,
            payload: asset
        });
    };
}

/**
 *  Clear the selected assets list in Redux in Redux.
 */
export function setAssetSelection(assets) {
    return (dispatch) => {
        dispatch({
            type: types.SET_ASSET_SELECTION,
            payload: assets
        });
    };
}

/**
 * Add an asset to the asset list in Redux.
 * By deafult add the asset as the first item, if atEnd is true, it's added at the end of the list.
 * @param {object} asse
 * @param {boolean} atEnd
 */
export function addAssets(asset, atEnd) {
    return (dispatch) => {
        dispatch({
            type: types.ADD_ASSETS,
            payload: {
                asset,
                atEnd
            }
        });
    };
}

/**
 * Delete assets, either permanent or moving them to the bin. Depending on type through the API and update Redux.
 * @param {array} assets
 * @param {string} type
 */
export function deleteAssets(assets, type) {
    return (dispatch) => {
        // Make an ugly distinction between perament and bin, since there is only a batch bin endpoint avaiable.
        if (type === 'permanent') {
            const promises = [];
            assets.forEach((assetId) => {
                promises.push(
                    Axios.delete(`${process.env.APP_ASSET_LIBRARY_URL}/assets/${assetId}`, { headers: { Authorization: token } }, { withCredentials: false })
                );
            });
            Promise.all(promises).then(() => {
                dispatch({
                    type: types.DELETE_ASSETS,
                    payload: assets
                });
            });
        } else {
            Axios.delete(
                `${process.env.APP_ASSET_LIBRARY_URL}/assets/batch-bin`,
                { headers: { Authorization: token }, data: { referenceIds: assets } },
                { withCredentials: false }
            ).then(() => {
                dispatch({
                    type: types.DELETE_ASSETS,
                    payload: assets
                });
            });
        }
    };
}

/**
 * Unbin assets through the API and update Redux.
 * @param {array} assets referenceId's of assets to unbin.
 */
export function unbinAssets(assets) {
    return async (dispatch) => {
        dispatch({
            type: types.SET_ASSETS_FETCHED,
            payload: false
        });

        const promises = [];
        assets.forEach((assetId) => {
            promises.push(
                Axios.put(`${process.env.APP_ASSET_LIBRARY_URL}/assets/${assetId}/unbin`, {}, { headers: { Authorization: token } }, { withCredentials: false })
            );
        });

        Promise.all(promises).then(() => {
            dispatch({
                type: types.UNBIN_ASSETS,
                payload: assets
            });

            dispatch({
                type: types.SET_ASSETS_FETCHED,
                payload: true
            });
        });
    };
}

/**
 * Approve assets, set the reviewState to approved through the API and update Redux.
 * @param {array} assets
 */
export function approveAssets(assets) {
    return (dispatch) => {
        assets.forEach((assetId) => {
            Axios.post(
                `${process.env.APP_ASSET_LIBRARY_URL}/assets/review-asset/${assetId}`,
                { approved: true },
                { headers: { Authorization: token } },
                { withCredentials: false }
            )
                .then(() => {})
                .catch((err) => {
                    console.error('There was an error approving the assets', err);
                });
        });

        dispatch({
            type: types.DELETE_ASSETS,
            payload: assets
        });
    };
}

/**
 * Update the metadata of an asset through the API and update Redux.
 * @param {string} assetId
 * @param {object} data the updated fields
 */
export function addAssetMetadata(assetId, data) {
    return async (dispatch) => {
        dispatch({
            type: types.ADD_ASSET_METADATA,
            payload: {
                assetId,
                data
            }
        });

        await setAssetMetadata(assetId, data);

        dispatch({
            type: types.SET_ASSET_SAVED,
            payload: assetId
        });
    };
}

/**
 * Update the metadata of a batch of assets through the API and update Redux.
 * @param {array} data
 */
export function addAssetsMetadataBatch(data) {
    return async (dispatch) => {
        dispatch({
            type: types.ADD_ASSETS_METADATA_BATCH,
            payload: data
        });

        await setAssetMetadataBatch(data);

        const payload = data.map((asset) => asset.referenceId);

        dispatch({
            type: types.SET_ASSETS_BATCH_SAVED,
            payload
        });
    };
}

/**
 * Update the metadata of a collection and update Redux.
 * @param {*} value
 * @param {string} key
 * @param {string} id
 */
export function addCollectionMetadata(value, key, id) {
    return async (dispatch) => {
        let result;
        try {
            result = await setCollectionMetadata(value, key, id);

            if (result) {
                dispatch({
                    type: types.ADD_COLLECTION_METADATA,
                    payload: {
                        value,
                        key,
                        id
                    }
                });
            }
        } catch (err) {
            console.log('There was an error adding the collection metadata', err);
        }
    };
}

/**
 * Add an asset to a collction and update Redux.
 * @param {string} collectionReferenceId
 * @param {string} assetReferenceId
 */
export function addAssetToCollection(collectionReferenceId, assetReferenceId) {
    return async (dispatch) => {
        const result = await newCollectionItem(collectionReferenceId, assetReferenceId);

        if (result) {
            dispatch({
                type: types.ADD_ASSET_TO_COLLECTION,
                payload: { collectionReferenceId, assetReferenceId }
            });
        }
    };
}

/**
 * Add a collection to a collection and update Redux.
 * @param {string} collectionReferenceId // Collection to add to
 * @param {string} targetId // Collection to add
 */
export function addCollectionToCollection(collectionReferenceId, targetId) {
    return async (dispatch) => {
        const result = await newCollectionItem(collectionReferenceId, targetId);

        if (result) {
            dispatch({
                type: types.ADD_COLLECTION_TO_COLLECTION,
                payload: { collectionReferenceId, targetId }
            });
        }
    };
}

/**
 * Fetch the collections from the API and store them in Redux.
 */
export function fetchCollections() {
    return async (dispatch, getState) => {
        dispatch({
            type: types.SET_COLLECTIONS_FETCHED,
            payload: false
        });

        const { filterExpired } = getState().contentspace;

        const result = await getCollections(undefined, filterExpired, false);

        if (result.collections && result.next) {
            dispatch({
                type: types.FETCH_COLLECTIONS,
                payload: { ...result, collectionsFiltered: false }
            });
        }
    };
}

/**
 * Fetch the next page of collections from the API and store them in Redux.
 */
export function fetchMoreCollections() {
    return async (dispatch, getState) => {
        const { filters, searchQuery } = getState().contentspace;
        const search = getCollectionSearch(filters, searchQuery);
        const next = getState().contentspace.collectionsNext;
        const filterExpired = getState().contentspace.filterExpired;
        const collectionsFiltered = Object.keys(search).length > 0;
        const result = collectionsFiltered ? await searchCollections(search, filterExpired, next) : await getCollections(next, filterExpired, false);

        if (result.collections && result.next) {
            dispatch({
                type: types.FETCH_MORE_COLLECTIONS,
                payload: { ...result, collectionsFiltered }
            });
        }
    };
}

/**
 * Fetch a list of all colections, including sub collections.
 * @returns {array}
 */
export function fetchAllCollections() {
    return async (dispatch) => {
        let next = 0;
        const allCollections = [];
        const fetch = async () => {
            const result = await getCollections(next, false, true);
            if (result.collections && result.next) {
                next = result.next;
                allCollections.push(...result.collections);
                if (next > -1) await fetch();
            }
        };
        await fetch();

        dispatch({
            type: types.FETCH_ALL_COLLECTIONS,
            payload: allCollections
        });
    };
}

/**
 * Fetch assets from the API and store them in Redux.
 */
export function fetchAssets() {
    return async (dispatch, getState) => {
        dispatch({
            type: types.SET_ASSETS_FETCHED,
            payload: false
        });
        const { locationView, assetSorting, filterExpired } = getState().contentspace;
        const result = await getAssets(locationView, assetSorting, filterExpired);

        if (result.assets && result.next) {
            dispatch({
                type: types.FETCH_ASSETS,
                payload: { ...result, assetsFiltered: false }
            });
        }
    };
}

/**
 * Fetch the next page of assets from the API and store them in Redux.
 */
export function fetchMoreAssets() {
    return async (dispatch, getState) => {
        const { filters, metadataInputs, locationView, searchQuery, itemsNext, assetSorting, filterExpired } = getState().contentspace;
        let combinedSearch = getAssetSearch(filters, metadataInputs, searchQuery);

        if (locationView === 'review') combinedSearch = { ...combinedSearch, pending: true };
        if (locationView === 'binned') combinedSearch = { ...combinedSearch, binned: true };

        const assetsFiltered = Object.keys(combinedSearch).length > 0;

        const result = assetsFiltered
            ? await searchAssets(combinedSearch, assetSorting, filterExpired, itemsNext)
            : await getAssets(locationView, assetSorting, filterExpired, itemsNext);

        if (result.assets && result.next) {
            dispatch({
                type: types.FETCH_MORE_ASSETS,
                payload: { ...result, assetsFiltered }
            });
        }
    };
}

/**
 * Fetch the asset count from the API and store it in Redux
 * The count reffers to binned assets and assets queued for review.
 */
export function fetchAssetCount() {
    return async (dispatch) => {
        const result = await getAssetCount();
        if (typeof result.reviewCount !== 'undefined' && typeof result.binnedCount !== 'undefined') {
            dispatch({
                type: types.FETCH_ASSET_COUNT,
                payload: result
            });
        }
    };
}

/**
 * Fetch the asset filters from the API and store them in Redux.
 * @param {boolean} refresh Should we merge with the existing filters or start fresh?
 */
export function fetchFilters(refresh) {
    return async (dispatch, getState) => {
        const { locationView } = getState().contentspace;

        if (refresh) {
            dispatch({
                type: types.SET_FILTERS_FETCHED,
                payload: false
            });
        }

        if (locationView === 'review' || locationView === 'binned') {
            await getAssetSearchFilters(getState(), dispatch, refresh);
        } else {
            let newFilters = [];

            try {
                const relevant = await getFilters(getState());
                if (refresh) {
                    newFilters = relevant;
                } else {
                    const { filters } = getState().contentspace;
                    newFilters = mergeFilters(filters, relevant);
                }
            } catch (error) {}

            newFilters.forEach((filter, index) => {
                if (
                    newFilters[index].options &&
                    newFilters[index].options.length > 0 &&
                    (filter.name === 'markets' || filter.name === 'departments' || filter.name === 'brands')
                ) {
                    newFilters[index].options.push({ value: Translation.get('filters.unassigned', 'content-space'), key: 'null' });
                }
            });

            dispatch({
                type: types.FETCH_POPULAR_FILTERS,
                payload: newFilters
            });
        }
    };
}

/**
 * Get concepts from the API and store them in Redux.
 */
export function fetchConcepts() {
    return async (dispatch) => {
        const concepts = await getConcepts();

        dispatch({
            type: types.FETCH_CONCEPTS,
            payload: concepts
        });
    };
}

/**
 * Replace the array of uploads in progress in Redux.
 * @param {aray} uploadsInProgress
 * @returns
 */
export function setConcepts(uploadsInProgress) {
    return (dispatch) => {
        dispatch({
            type: types.SET_CONCEPTS,
            payload: uploadsInProgress
        });
    };
}

/**
 * Set the filter expired flag.
 * @param {boolean} value
 * @returns
 */
export function setFilterExpired(value) {
    return (dispatch) => {
        dispatch({
            type: types.SET_FILTER_EXPIRED,
            payload: value
        });
    };
}

/**
 * Create a new collection on the API and store it in Redux.
 * @param {string} name
 */
export function createCollection(name, thumbnail, collectionParentId) {
    return async (dispatch) => {
        const collection = await newCollection(name, thumbnail);

        if (collection && !collectionParentId) {
            dispatch({ type: types.NEW_COLLECTION, payload: collection });
        }

        return collection;
    };
}

/**
 * Fetch the collection items of a specific collections from the API and store them in Redux.
 * @param {string} collectionReferenceId
 * @param {boolean} silent do it for a collection not in view, so the spinner shouldn't be shown.
 */
export function fetchCollectionItems(collectionReferenceId, silent) {
    return async (dispatch, getState) => {
        if (!silent) {
            dispatch({
                type: types.SET_ASSETS_FETCHED,
                payload: false
            });
        }
        const { assetSorting, filterExpired } = getState().contentspace;

        const result = await getCollectionItems(collectionReferenceId, assetSorting, filterExpired);
        if (result !== null) {
            dispatch({
                type: types.FETCH_COLLECTION_ITEMS,
                payload: { collectionReferenceId, ...result }
            });
        }
    };
}

/**
 * Fetch the next page of collection items of a specific collections from the API and store them in Redux.
 * @param {string} collectionReferenceId
 */
export function fetchMoreCollectionItems(collectionReferenceId) {
    return async (dispatch, getState) => {
        const { filters, metadataInputs, collectionItemsNext, searchQuery, collectionItemsSorting, filterExpired } = getState().contentspace;
        const next = collectionItemsNext[collectionReferenceId];
        const assetSorting = collectionItemsSorting[collectionReferenceId] ? collectionItemsSorting[collectionReferenceId] : {};

        const combinedSearch = getAssetSearch(filters, metadataInputs, searchQuery);

        let result;
        if (Object.keys(combinedSearch).length > 0) {
            result = await searchCollectionItems(combinedSearch, collectionReferenceId, assetSorting, filterExpired, next);
        } else {
            result = await getCollectionItems(collectionReferenceId, assetSorting, filterExpired, next);
        }

        if (result && result.items) {
            dispatch({
                type: types.FETCH_MORE_COLLECTION_ITEMS,
                payload: { collectionReferenceId, items: result.items, next: result.next }
            });
        }
    };
}

/**
 * Remove an asset from a collection on the API and stor the update in Redux.
 * @param {string} collectionReferenceId
 * @param {string} assetReferenceId
 */
export function removeCollectionItem(collectionReferenceId, assetReferenceId) {
    return async (dispatch) => {
        const success = await deleteCollectionItem(collectionReferenceId, assetReferenceId);

        if (success) {
            dispatch({
                type: types.DELETE_COLLECTION_ITEM,
                payload: { collectionReferenceId, assetReferenceId }
            });
        }

        return success;
    };
}

/**
 * Remove a collection through the API and store the update in Redux.
 * @param {string} collectionReferenceId
 */
export function removeCollection(collectionReferenceId) {
    return async (dispatch) => {
        const success = await deleteCollection(collectionReferenceId);

        if (success) {
            dispatch({
                type: types.DELETE_COLLECTION,
                payload: collectionReferenceId
            });
        }

        return success;
    };
}

/**
 *  Remove all assets flagged as binned through the API and store the update in Redux.
 */
export function emptyBin() {
    return async (dispatch) => {
        // const response = await Axios.delete(`${process.env.APP_ASSET_LIBRARY_URL}/assets`, { headers: { Authorization: token } }, { withCredentials: false });
        // const success = response.data && response.data.success

        Axios.delete(`${process.env.APP_ASSET_LIBRARY_URL}/assets`, { headers: { Authorization: token } }, { withCredentials: false });
        const success = true;

        if (success) {
            dispatch({
                type: types.EMPTY_BIN,
                payload: success
            });
        }
    };
}

/**
 * Get a Content Space access token from the API and store it in Redux
 */
export function fetchToken() {
    return async (dispatch) => {
        const t = await AssetHelpers.getToken();

        token = t;

        if (t) {
            dispatch({
                type: types.FETCH_TOKEN,
                payload: t
            });
        }
    };
}

/**
 * Store the resources in Redux.
 * @param {object} resources
 */
export function setResources(resources) {
    return (dispatch) => {
        dispatch({
            type: types.SET_RESOURCES,
            payload: resources
        });
    };
}

/**
 * Store a choosen location view in Redux.
 * @param {string} locationView
 */
export function setLocationView(locationView) {
    return (dispatch) => {
        dispatch({
            type: types.SET_LOCATION_VIEW,
            payload: locationView
        });
    };
}

/**
 * Store the choosen collection refference Id in Redux.
 * This is the refference Id of the collection currently in view
 * @param {string} collectionReferenceId
 */
export function setCollectionReferenceId(collectionReferenceId) {
    return (dispatch) => {
        dispatch({
            type: types.SET_COLLECTION_REFERENCE_ID,
            payload: collectionReferenceId
        });
    };
}

/**
 * Add or remove an asset referenceId from the savedssets array
 * @param {string} referenceId
 */
export function setSavedAssets(referenceId) {
    return (dispatch) => {
        dispatch({
            type: types.SET_ASSET_SAVED,
            payload: referenceId
        });
    };
}

/**
 * Clear the savedAssets array
 */
export function resetSavedAssets() {
    return (dispatch) => {
        dispatch({
            type: types.SET_ASSET_SAVED
        });
    };
}

/**
 * Clear the search query
 */
export function resetSearchQuery() {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_SEARCH_QUERY,
            payload: { query: '' }
        });
    };
}

/**
 * Store the aset sorting params in Redux and execute the sorted asset call on the API.
 * @param {object} assetSorting
 */
export function sortAssets(assetSorting) {
    return async (dispatch, getState) => {
        const { locationView } = getState().contentspace;

        dispatch({
            type: types.SET_ASSET_SORTING,
            payload: assetSorting
        });

        if (locationView === 'assets' || locationView === 'review' || locationView === 'binned') {
            await getAssetSearchFilters(getState(), dispatch);
        } else if (locationView === 'collectionItems') {
            await getCollectionItemsSearchFilters(getState(), dispatch);
        }
    };
}

/**
 * Get the current filters from the API.
 * @param {object} currentState
 * @returns formatted response
 */
async function getFilters(currentState) {
    let response = {},
        collectionsResponse = {};
    const { metadataInputs, collectionMetadataInputs, collectionReferenceId, filterExpired = false } = currentState.contentspace;
    const params = {};
    if (filterExpired) {
        params.filterExpired = true;
    }

    try {
        if (collectionReferenceId) {
            response = await Axios.get(
                `${process.env.APP_ASSET_LIBRARY_URL}/statistics/popular/${collectionReferenceId}/collection-items-filters`,
                { headers: { Authorization: token }, params },
                { withCredentials: false }
            );
        } else {
            response = await Axios.get(
                `${process.env.APP_ASSET_LIBRARY_URL}/statistics/popular/filters`,
                { headers: { Authorization: token }, params },
                { withCredentials: false }
            );
        }
        collectionsResponse = await Axios.get(
            `${process.env.APP_ASSET_LIBRARY_URL}/statistics/popular/collection-filters`,
            { headers: { Authorization: token }, params },
            { withCredentials: false }
        );
    } catch (error) {}

    return [
        ...transformFilters(response.data, 'assets', metadataInputs),
        ...transformFilters(collectionsResponse.data, 'collections', collectionMetadataInputs)
    ];
}

/**
 * Update asset metadata on the API.
 * @param {string} referenceId
 * @param {object} data
 * @returns formatted response
 */
async function setAssetMetadata(referenceId, data) {
    // The backend wants the custom data as separate key value pairs
    const newData = {
        ...data,
        ...data.custom
    };
    delete newData.custom;

    await Axios.put(`${process.env.APP_ASSET_LIBRARY_URL}/assets/${referenceId}`, newData, { headers: { Authorization: token } }, { withCredentials: false })
        .then(() => {})
        .catch((err) => {
            console.log('There was an error setting the asset metadata', err);
        });
}

/**
 * Batch update metadata on the API.
 * @param {array} data
 */
async function setAssetMetadataBatch(data) {
    const newData = data.map((asset) => {
        const newAsset = {
            ...asset,
            ...asset.custom
        };
        delete newAsset.custom;
        return newAsset;
    });

    await Axios.put(
        `${process.env.APP_ASSET_LIBRARY_URL}/assets/batch-update`,
        { assets: newData },
        { headers: { Authorization: token } },
        { withCredentials: false }
    )
        .then(() => {})
        .catch((err) => {
            console.log('There was an error setting the assets metadata', err);
        });
}

/**
 * Update collection metadata n the API.
 * @param {*} value
 * @param {string} key
 * @param {string} referenceId
 * @returns formatted response
 */
async function setCollectionMetadata(value, key, referenceId) {
    const data = {
        [key]: value
    };

    const response = await Axios.put(
        `${process.env.APP_ASSET_LIBRARY_URL}/collections/${referenceId}`,
        data,
        { headers: { Authorization: token } },
        { withCredentials: false }
    );

    if (response.data && response.data.collection) {
        return response.data.collection;
    }
    return null;
}

/**
 * Get assets from the API.
 * @param {string} locationView
 * @param {object} assetSorting
 * @param {number} next
 * @returns formatted response
 */
async function getAssets(locationView, assetSorting, filterExpired, next) {
    let url;

    //If assetSorting is empty (initial load), get last set filter from view state;
    if (Object.keys(assetSorting).length === 0) assetSorting = ViewState.get('contentSpace', 'sorting', 'local') || {};
    if (!locationView || locationView === 'assets') url = '/assets';
    if (locationView === 'review') url = '/assets/pending-review';
    if (locationView === 'binned') url = '/assets/bin';

    const params = {};
    if (next) {
        params.next = next;
    }
    if (filterExpired) {
        params.filterExpired = true;
    }
    if (assetSorting && assetSorting.sortField && assetSorting.sortDirection) {
        params.sortField = assetSorting.sortField;
        params.sortDirection = parseInt(assetSorting.sortDirection);
    }

    const response = await Axios.get(`${process.env.APP_ASSET_LIBRARY_URL}${url}`, { headers: { Authorization: token }, params }, { withCredentials: false });

    if (response.data && response.data.assets && response.data.next) {
        const assets = AssetHelpers.transformAssets(response.data.assets);

        return { assets, next: response.data.next };
    }
    return null;
}

/**
 * Get the asset count (binned & queued for review) from the API
 * @returns formatted response
 */
async function getAssetCount() {
    const response = await Axios.get(
        `${process.env.APP_ASSET_LIBRARY_URL}/account/asset-count`,
        { headers: { Authorization: token } },
        { withCredentials: false }
    );

    if (response && response.data) {
        const reviewCount = response.data.pending ? response.data.pending : 0;
        const binnedCount = response.data.binned ? response.data.binned : 0;
        return { reviewCount, binnedCount };
    }
}

/**
 * Get the collections from the API.
 * @param {number} next
 * @param {boolean} filterExpired
 * @param {boolean} includeSubCollections
 * @returns formatted response
 */
async function getCollections(next, filterExpired, includeSubCollections) {
    const params = {};
    if (next) {
        params.next = next;
    }
    if (filterExpired) {
        params.filterExpired = true;
    }
    if (includeSubCollections) {
        params.includeSubCollections = true;
    }
    const response = await Axios.get(
        `${process.env.APP_ASSET_LIBRARY_URL}/collections`,
        { headers: { Authorization: token }, params },
        { withCredentials: false }
    );

    if (response.data && response.data.collections && response.data.next) {
        return { collections: response.data.collections, next: response.data.next };
    }

    return null;
}

/**
 * Get the collection items / assets for a specific collection from the API.
 * @param {string} collectionReferenceId
 * @param {object} assetSorting
 * @returns formatted response
 */
async function getCollectionItems(collectionReferenceId, assetSorting, filterExpired = false, next) {
    const params = {};
    if (next) {
        params.next = next;
    }
    if (filterExpired) {
        params.filterExpired = true;
    }
    if (assetSorting && assetSorting.sortField && assetSorting.sortDirection) {
        params.sortField = assetSorting.sortField;
        params.sortDirection = parseInt(assetSorting.sortDirection);
    }

    const response = await Axios.get(
        `${process.env.APP_ASSET_LIBRARY_URL}/collections/${collectionReferenceId}/items`,
        { headers: { Authorization: token }, params },
        { withCredentials: false }
    );

    if (response.data && response.data.items) {
        const items = AssetHelpers.transformAssets(response.data.items);
        return { ...response.data, items };
    }

    return null;
}

/**
 * Get the concepts (unstored assets) from the API
 * @returns list of concepts
 */
async function getConcepts() {
    const response = await Axios.get(`${process.env.APP_ASSET_LIBRARY_URL}/assets/concepts`, { headers: { Authorization: token } }, { withCredentials: false });
    if (response.data && response.data.assets) {
        return response.data.assets;
    }
}

/**
 * Add an asset to a collection on the API
 * @param {string} collectionReferenceId
 * @param {string} assetReferenceId
 * @returns boolean, success or not
 */
async function newCollectionItem(collectionReferenceId, targetId) {
    const response = await Axios.post(
        `${process.env.APP_ASSET_LIBRARY_URL}/collections/${collectionReferenceId}/items`,
        { targetId },
        { headers: { Authorization: token } },
        { withCredentials: false }
    );

    if (response.data && response.data.success) {
        return true;
    }

    return false;
}

/**
 * Textbased search on assets.
 * @param {string} query
 * @returns
 */
export function onSearchAssets(query) {
    return async (dispatch, getState) => {
        dispatch({
            type: types.SET_ASSETS_FETCHED,
            payload: false
        });

        let assets, next, assetsFiltered;
        const { filters, metadataInputs, locationView, assetSorting, filterExpired } = getState().contentspace;

        let combinedSearch = getAssetSearch(filters, metadataInputs, { query });

        if (locationView === 'review') combinedSearch = { ...combinedSearch, pending: true };
        if (locationView === 'binned') combinedSearch = { ...combinedSearch, binned: true };

        if (Object.keys(combinedSearch).length > 0) {
            const result = await searchAssets(combinedSearch, assetSorting, filterExpired);
            assets = result.assets;
            next = result.next;
            assetsFiltered = true;
        } else {
            const result = await getAssets(locationView, assetSorting, filterExpired);
            assets = result.assets;
            next = result.next;
            assetsFiltered = false;
        }

        dispatch({
            type: types.UPDATE_SEARCH_QUERY,
            payload: { query }
        });

        dispatch({
            type: types.FETCH_ASSETS,
            payload: { assets, next, assetsFiltered }
        });
    };
}

/**
 * Fetch filtered assets from the API.
 * @param {object} filters
 * @param {object} assetSorting
 * @param {boolean} filterExpired
 * @param {number} next
 * @returns formatted response
 */
async function searchAssets(filters, assetSorting, filterExpired = false, next) {
    const params = {
        filters
    };
    if (next) {
        params.next = next;
    }
    if (filterExpired) {
        params.filterExpired = true;
    }
    if (assetSorting && assetSorting.sortField && assetSorting.sortDirection) {
        params.sortField = assetSorting.sortField;
        params.sortDirection = parseInt(assetSorting.sortDirection);
    }
    const response = await Axios.post(
        `${process.env.APP_ASSET_LIBRARY_URL}/assets/search`,
        params,
        { headers: { Authorization: token } },
        { withCredentials: false }
    );

    if (response.data && response.data.assets && response.data.relevant) {
        const assets = AssetHelpers.transformAssets(response.data.assets);

        return { assets, relevant: response.data.relevant, next: response.data.next };
    }
}

/**
 * Perfom a search on collections on the API and update Redux with the result.
 * @param {string} query
 */
export function onSearchCollections(query) {
    return async (dispatch, getState) => {
        dispatch({
            type: types.SET_COLLECTIONS_FETCHED,
            payload: false
        });

        let collections, next, collectionsFiltered;

        const { filterExpired, filters } = getState().contentspace;
        const search = getCollectionSearch(filters, { query });

        if (Object.keys(search).length > 0) {
            const result = await searchCollections(search, filterExpired);
            collections = result.collections;
            next = result.next;
            collectionsFiltered = true;
        } else {
            const result = await getCollections(undefined, filterExpired, false);
            collections = result.collections;
            next = result.next;
            collectionsFiltered = false;
        }

        dispatch({
            type: types.UPDATE_SEARCH_QUERY,
            payload: { query }
        });

        dispatch({
            type: types.FETCH_COLLECTIONS,
            payload: { collections, next: next, collectionsFiltered }
        });
    };
}

/**
 * Fetch filtered collections from the API.
 * @param {object} filters
 * @param {number} next
 * @returns response
 */
async function searchCollections(filters, filterExpired = false, next) {
    const params = {
        filters
    };
    if (next) {
        params.next = next;
    }
    if (filterExpired) {
        params.filterExpired = true;
    }
    const response = await Axios.post(
        `${process.env.APP_ASSET_LIBRARY_URL}/collections/search`,
        params,
        { headers: { Authorization: token } },
        { withCredentials: false }
    );

    if (response.data && response.data.collections && response.data.relevant) {
        return response.data;
    }
}

/**
 * Fetch the filtered list of collection items / assets for a specific collection from the API.
 * @param {object} filters
 * @param {string} collectionReferenceId
 * @param {object} assetSorting
 * @param {boolean} filterExpired
 * @param {number} next
 * @returns formatted response
 */
async function searchCollectionItems(filters, collectionReferenceId, assetSorting, filterExpired = false, next) {
    const params = { filters };
    if (next) {
        params.next = next;
    }
    if (filterExpired) {
        params.filterExpired = true;
    }
    if (assetSorting && assetSorting.sortField && assetSorting.sortDirection) {
        params.sortField = assetSorting.sortField;
        params.sortDirection = parseInt(assetSorting.sortDirection);
    }

    const response = await Axios.post(
        `${process.env.APP_ASSET_LIBRARY_URL}/collections/${collectionReferenceId}/items/search`,
        params,
        { headers: { Authorization: token } },
        { withCredentials: false }
    );

    if (response.data && response.data.items) {
        const items = AssetHelpers.transformAssets(response.data.items);

        return { ...response.data, items };
    }
}

/**
 * Perfom a search on collection items on the API and update Redux with the result.
 * @param {string} query
 */
export function onSearchCollectionItems(query) {
    return async (dispatch, getState) => {
        dispatch({
            type: types.SET_ASSETS_FETCHED,
            payload: false
        });

        const { filters, metadataInputs, collectionReferenceId, collectionItemsSorting, filterExpired } = getState().contentspace;

        const searchQuery = { query };
        const combinedSearch = getAssetSearch(filters, metadataInputs, searchQuery);
        const assetSorting = collectionItemsSorting[collectionReferenceId] ? collectionItemsSorting[collectionReferenceId] : {};

        let items, collections, relevant, next, filtered;
        if (Object.keys(combinedSearch).length > 0) {
            const result = await searchCollectionItems(combinedSearch, collectionReferenceId, assetSorting, filterExpired);
            collections = result.collections;
            items = result.items;
            next = result.next;
            relevant = transformFilters(result.relevant, 'assets', metadataInputs);
            filtered = true;
        } else {
            const result = await getCollectionItems(collectionReferenceId, assetSorting, filterExpired);
            collections = result.collections;
            items = result.items;
            next = result.next;
            relevant = transformFilters(result.relevant, 'assets', metadataInputs);
            filtered = false;
        }

        const newFilters = mergeFilters(filters, relevant);

        dispatch({
            type: types.UPDATE_SEARCH_QUERY,
            payload: { query }
        });

        dispatch({
            type: types.FETCH_POPULAR_FILTERS,
            payload: newFilters
        });

        dispatch({
            type: types.FETCH_COLLECTION_ITEMS,
            payload: { items, collections, next, filtered, collectionReferenceId }
        });
    };
}

/**
 * Perform API call to create a new collection
 * @param {string} name
 * @returns
 */
async function newCollection(name, thumbnail) {
    const response = await Axios.post(
        `${process.env.APP_ASSET_LIBRARY_URL}/collections`,
        { name, thumbnail },
        { headers: { Authorization: token } },
        { withCredentials: false }
    );

    if (response.data && response.data.collection) {
        return { ...response.data.collection, preview: [] };
    }

    return false;
}

/**
 * Perform API call to delete a collection
 * @param {string} collectionReferenceId
 */
async function deleteCollection(collectionReferenceId) {
    const response = await Axios.delete(
        `${process.env.APP_ASSET_LIBRARY_URL}/collections/${collectionReferenceId}`,
        { headers: { Authorization: token } },
        { withCredentials: false }
    );

    return response.data && response.data.success;
}

/**
 * Perform API call te remve an asset from a collection
 * @param {string} collectionReferenceId
 * @param {string} assetReferenceId
 * @returns
 */
async function deleteCollectionItem(collectionReferenceId, assetReferenceId) {
    const response = await Axios.delete(
        `${process.env.APP_ASSET_LIBRARY_URL}/collections/${collectionReferenceId}/items/${assetReferenceId}`,
        { headers: { Authorization: token } },
        { withCredentials: false }
    );

    return response.data && response.data.success;
}

/**
 * Format the filters and search query into the right object to be used in API calls on assets.
 * @param {*} filters
 * @param {*} metadataInputs
 * @param {*} searchQuery
 * @returns combined filters object
 */
function getAssetSearch(filters, metadataInputs, searchQuery) {
    const combinedFilters = {};
    filters.forEach((filter) => {
        if (filter.name === 'tags' && filter.selection.length > 0) {
            combinedFilters.tags = [];
            filter.selection.forEach((tag) => combinedFilters.tags.push(tag));
        } else if (filter.name === 'vision_tags' && filter.selection.length > 0) {
            combinedFilters.vision_tags = [];
            filter.selection.forEach((vision_tag) => combinedFilters.vision_tags.push(vision_tag));
        } else if (filter.name === 'categories' && filter.selection.length > 0) {
            combinedFilters.categories = [];
            filter.selection.forEach((category) => combinedFilters.categories.push(category));
        } else if (filter.name === 'countries' && filter.selection.length > 0) {
            combinedFilters.countries = [];
            filter.selection.forEach((country) => combinedFilters.countries.push(country));
        } else if (filter.name === 'cities' && filter.selection.length > 0) {
            combinedFilters.cities = [];
            filter.selection.forEach((city) => combinedFilters.cities.push(city));
        } else if (filter.name === 'resolutions' && filter.selection.length > 0) {
            combinedFilters.resolutions = [];
            filter.selection.forEach((resolution) => combinedFilters.resolutions.push(resolution));
        } else if (filter.name === 'area_levels' && filter.selection.length > 0) {
            combinedFilters.area_levels = [];
            filter.selection.forEach((area_level) => combinedFilters.area_levels.push(area_level));
        } else if (filter.name === 'licenses' && filter.selection.length > 0) {
            combinedFilters.licenses = [];
            filter.selection.forEach((license) => combinedFilters.licenses.push(license));
        } else if (filter.selection.length > 0) {
            const target = metadataInputs.find((mi) => mi.key === filter.name);

            if (target) {
                combinedFilters[filter.name] = [];
                filter.selection.forEach((sel) => combinedFilters[filter.name].push(sel));
            }
        }
    });
    if (searchQuery && searchQuery.query && searchQuery.query.length > 0) {
        combinedFilters.query = searchQuery.query;
    }

    return combinedFilters;
}

/**
 * Format the filters and search query into the right object to be used in API calls on collections.
 * @param {object} filters
 * @param {object} searchQuery
 * @returns
 */
function getCollectionSearch(filters, searchQuery) {
    const combinedFilters = {};
    filters.forEach((filter) => {
        if (filter.name === 'year' && filter.selection.length > 0) {
            combinedFilters.year = filter.selection[0];
        }
    });
    if (searchQuery && searchQuery.query && searchQuery.query.length > 0) {
        combinedFilters.query = searchQuery.query;
    }

    return combinedFilters;
}

/**
 * Fetch filtered assets from the API and update Redux with the results.
 * @param {object} currentState
 * @param {function} dispatch
 * @param {boolean} refresh
 */
async function getAssetSearchFilters(currentState, dispatch, refresh) {
    const { metadataInputs, locationView, searchQuery, assetSorting, filterExpired } = currentState.contentspace;
    dispatch({
        type: types.SET_ASSETS_FETCHED,
        payload: false
    });

    // Check when the last request has been made and only update redux if all requests have finished
    lastAssetFilterSearch = Date.now();
    const lastAssetFilterSearchCheck = lastAssetFilterSearch;

    let { filters } = currentState.contentspace;

    // If the route is changed, all filters need to refresh
    if (refresh) filters = await getFilters(currentState);

    let combinedSearch = getAssetSearch(filters, metadataInputs, searchQuery);

    if (locationView === 'review') combinedSearch = { ...combinedSearch, pending: true };
    if (locationView === 'binned') combinedSearch = { ...combinedSearch, binned: true };

    let result, assets, relevant, next, assetsFiltered;

    if (Object.keys(combinedSearch).length > 0) {
        result = await searchAssets(combinedSearch, assetSorting, filterExpired);
        assets = result.assets;
        next = result.next;
        relevant = transformFilters(result.relevant, 'assets', metadataInputs);
        assetsFiltered = true;
    } else {
        result = await getAssets(locationView, assetSorting, filterExpired);
        assets = result.assets;
        next = result.next;
        relevant = await getFilters(currentState);
        assetsFiltered = false;
    }

    const newFilters = mergeFilters(filters, relevant);
    if (lastAssetFilterSearch === lastAssetFilterSearchCheck) {
        dispatch({
            type: types.FETCH_POPULAR_FILTERS,
            payload: newFilters
        });

        dispatch({
            type: types.FETCH_ASSETS,
            payload: { assets, next, assetsFiltered }
        });
    }
}

/**
 * Fetch filtered collections from the API and update Redux with the results.
 * @param {object} currentState
 * @param {function} dispatch
 */
async function getCollectionSearchFilters(currentState, dispatch) {
    dispatch({
        type: types.SET_COLLECTIONS_FETCHED,
        payload: false
    });

    // Check when the last request has been made and only update redux if all requests have finished
    lastCollectionFilterSearch = Date.now();
    const lastCollectionFilterSearchCheck = lastCollectionFilterSearch;

    const { filters, searchQuery, filterExpired } = currentState.contentspace;

    const search = getCollectionSearch(filters, searchQuery);

    let collections;
    let relevant;
    let next;
    let collectionsFiltered;

    if (Object.keys(search).length > 0) {
        const result = await searchCollections(search, filterExpired);
        collections = result.collections;
        next = result.next;
        relevant = transformFilters(result.relevant, 'collections', currentState.contentspace.collectionMetadataInputs);
        collectionsFiltered = true;
    } else {
        const result = await getCollections(undefined, filterExpired, false);
        collections = result.collections;
        next = result.next;
        relevant = await getFilters(currentState);
        collectionsFiltered = false;
    }

    const newFilters = mergeFilters(filters, relevant);

    if (lastCollectionFilterSearch === lastCollectionFilterSearchCheck) {
        dispatch({
            type: types.FETCH_POPULAR_FILTERS,
            payload: newFilters
        });

        dispatch({
            type: types.FETCH_COLLECTIONS,
            payload: { collections, next, collectionsFiltered }
        });
    }
}

/**
 * Fetch filtered collections from the API and update Redux with the results.
 * @param {object} currentState
 * @param {function} dispatch
 * @param {boolean} refresh
 */
async function getCollectionItemsSearchFilters(currentState, dispatch, refresh) {
    dispatch({
        type: types.SET_ASSETS_FETCHED,
        payload: false
    });

    // Check when the last request has been made and only update redux if all requests have finished
    lastCollectionItemsFilterSearch = Date.now();
    const lastCollectionItemsFilterSearchCheck = lastCollectionItemsFilterSearch;

    const { metadataInputs, collectionReferenceId, searchQuery, collectionItemsSorting, filterExpired } = currentState.contentspace;
    let { filters } = currentState.contentspace;

    // If the route is changed, all filters need to refresh
    if (refresh) filters = [];

    const combinedSearch = getAssetSearch(filters, metadataInputs, searchQuery);
    const assetSorting = collectionItemsSorting[collectionReferenceId] ? collectionItemsSorting[collectionReferenceId] : {};

    let items, collections, relevant, next, filtered;
    if (Object.keys(combinedSearch).length > 0) {
        const result = await searchCollectionItems(combinedSearch, collectionReferenceId, assetSorting, filterExpired);
        collections = result.collections;
        items = result.items;
        next = result.next;
        relevant = transformFilters(result.relevant, 'assets', metadataInputs);
        filtered = true;
    } else {
        const result = await getCollectionItems(collectionReferenceId, assetSorting, filterExpired);
        collections = result.collections;
        items = result.items;
        next = result.next;
        relevant = await getFilters(currentState);
        filtered = false;
    }

    const newFilters = mergeFilters(filters, relevant);

    if (lastCollectionItemsFilterSearch === lastCollectionItemsFilterSearchCheck) {
        dispatch({
            type: types.FETCH_POPULAR_FILTERS,
            payload: newFilters
        });

        dispatch({
            type: types.FETCH_COLLECTION_ITEMS,
            payload: { items, collections, next, filtered, collectionReferenceId }
        });
    }
}

/**
 * Marge the defined filters with the relevant filters from the API.
 * @param {array} filters
 * @param {array} relevant
 * @returns filter object
 */
function mergeFilters(filters, relevant) {
    // Merge options
    function mergeOptions(originalOptions, relevantOptions = []) {
        let newRelevantOptions = [...relevantOptions];
        const newOptions = originalOptions.map((o) => {
            const found = relevantOptions.find((r) => r.key === o.key);
            if (o.key !== 'null') o.count = found ? found.count : 0;
            if (found) newRelevantOptions = [...newRelevantOptions].filter((n) => n.key !== found.key);

            if (found && o.subOptions) {
                o.subOptions = mergeOptions(o.subOptions, found.subOptions);
            } else if (!found) {
                o.subOptions = [];
            }
            return o;
        });
        const finalOptions = [...newOptions, ...newRelevantOptions].sort((a, b) => (a.count > b.count ? -1 : 1));

        return finalOptions;
    }

    let originalFilters = [...filters];
    let relevantFilters = [...relevant];

    if (originalFilters.length > 0) {
        originalFilters = originalFilters.map((of) => {
            const found = relevantFilters.find((rf) => rf.name === of.name);

            // Update the count for options
            if (found && of.options) {
                of.options = mergeOptions(of.options, found.options);
                relevantFilters = relevantFilters.filter((f) => f.name !== found.name);
            }

            return of;
        });
    }
    const finalFilters = [...originalFilters, ...relevantFilters];
    return finalFilters;
}

/**
 * Transform the filters to the right format for display purposes.
 * @param {object} filters
 * @param {string} type
 * @param {array} metadataInputs
 * @returns Transformed filter array
 */
function transformFilters(filters = {}, type, metadataInputs) {
    const names = {
        tags: 'Tags',
        vision_tags: 'Auto tags',
        resolutions: 'Resolution',
        countries: 'Country',
        cities: 'City',
        area_levels: 'Area',
        categories: 'File type',
        licenses: 'License',
        year: 'Creation date',
        subCategories: 'Category'
    };
    const options = {};

    const getType = (key) => {
        if (key === 'year') return 'single';
        if (key === 'subCategories') return 'multipleSubs';
        return 'multiple';
    };

    metadataInputs.forEach((input) => {
        if (!names[input.key]) {
            names[input.key] = input.name;
        }
        if (input && input.items && input.items.length > 0) {
            options[input.key] = [...input.items];
        }
    });

    const transformedFilters = Object.keys(filters)
        .filter((key) => Array.isArray(filters[key]))
        .map((key) => {
            const transformedFilter = {
                label: names[key],
                name: key,
                type: getType(key),
                filterType: type,
                selection: [],
                options: filters[key].map((item) => {
                    if (Setup.get(key) && Setup.get(key)[item._id]) {
                        return { value: Setup.get(key)[item._id], key: item._id, count: item.count };
                    } else if (options[key]) {
                        const option = options[key].find((o) => o.key === item._id);
                        if (option) {
                            if (Array.isArray(option.subitems)) {
                                const transformedSubItems = item.subitems.map((subitem) => {
                                    const subOption = option.subitems.find((so) => so.key === subitem._id.split('.')[1]);
                                    if (subOption) {
                                        return { value: subOption.name, key: subitem._id, count: subitem.count };
                                    }
                                });
                                return {
                                    value: option.name,
                                    key: item._id,
                                    count: item.count,
                                    subOptions: transformedSubItems
                                };
                            } else {
                                return { value: option.name, key: item._id, count: item.count };
                            }
                        }
                    }
                    return { value: item.label ? item.label : item._id, key: item._id, count: item.count };
                })
            };
            return transformedFilter;
        });
    return transformedFilters;
}

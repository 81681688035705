const tags = [];

export default { // eslint-disable-line
    accept: undefined,
    allCollections: [],
    allCollectionsFetched: false,
    assetFilterOrder: undefined,
    assetsFetched: false,
    assetsFiltered: false,
    assetSorting: {},
    binnedCount: 0,
    collectionItems: {},
    collectionItemsFiltered: [],
    collectionItemsNext: {},
    collectionItemsSorting: {},
    collectionMetadataInputs: [
        {
            name: 'Collection name',
            key: 'name',
            type: 'textfield'
        },
        {
            name: 'Thumbnail',
            key: 'thumbnail',
            type: 'assetGalleryInput'
        }
    ],
    collectionReferenceId: null,
    collections: [],
    collectionsFetched: false,
    collectionsFiltered: false,
    collectionSubcollections: {},
    filterExpired: false,
    filters: [],
    filtersFetched: false,
    locationView: undefined,
    metadataInputs: [
        {
            name: 'Asset title',
            key: 'title',
            type: 'textfield'
        },
        {
            name: 'Add to collection',
            key: 'collections',
            type: 'autocomplete',
            selection: [],
            items: []
        },
        {
            name: 'Tags',
            key: 'tags',
            type: 'autocomplete',
            selection: [],
            items: tags,
            acceptArbitraryValue: true
        },
        {
            name: 'License',
            key: 'license',
            type: 'single',
            selection: [],
            items: [
                {
                    name: 'Free for commercial use',
                    key: 'public'
                },
                {
                    name: 'Commercial use',
                    key: 'commercial'
                },
                {
                    name: 'Personal use',
                    key: 'personal'
                }
            ]
        }
    ],
    metadataInputsRequired: undefined,
    metadataInputsGrouped: undefined,
    items: [],
    itemsNext: 0,
    reviewCount: 0,
    savedAssets: [],
    searchQuery: undefined,
    selectedAssets: [],
    tags: tags,
    token: null,
    uploadsInProgress: [],
    uploadsInProgressFetched: false
};

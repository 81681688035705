import { produce, current, setAutoFreeze } from 'immer';
import unset from 'lodash/unset';
import setWith from 'lodash/setWith';
import cloneDeep from 'helpers/cloneDeep';
import types from './types.js';

export default (state = {}, action) => { // eslint-disable-line
    let result = {};
    let componentData = {};
    setAutoFreeze(false);

    switch (action.type) {
        case types.RESETDATA:
            return produce(state, (draft) => {
                componentData = cloneDeep(action.data);
                draft[action.componentName] = componentData;
            });

        // Set data to this version
        case types.SETDATA:
            return produce(state, (draft) => {
                if (state[action.componentName]) {
                    componentData = cloneDeep(state[action.componentName]);
                }
                if (action.data) {
                    componentData = { ...componentData, ...action.data };
                }
                draft[action.componentName] = componentData;
            });

        // Set model
        // This uses a structure for models to write the correct value
        case types.SETMODEL:
            return produce(state, (draft) => {
                const data = current(draft);

                // Loop through model to set values. E.g. website.copy.[[language]].title
                let activeObject = data[action.componentName];
                let activeDraft = draft[action.componentName];

                const model = action.model;
                const modelParts = model.split('.');

                // Loop through items and write value
                modelParts.forEach((part, i) => {
                    // Create an empty object if needed
                    if (typeof activeObject[part] !== 'object' || activeObject[part] === null || Array.isArray(activeObject[part])) {
                        activeDraft[part] = {};

                        // Set the activeObject to an empty object, but only if it's not the last part (otherwise this.props can be affected inside a shouldComponentUpdate)
                        if (modelParts.length - 1 !== i) activeObject[part] = {};
                    }

                    // This is the last part, we've reached the correct path. Set the new value.
                    if (modelParts.length - 1 === i) {
                        activeDraft[part] = action.value;
                    }

                    activeDraft = activeDraft[part];
                    activeObject = activeObject[part];
                });
            });
        case types.SETMODELS:
            const newState = cloneDeep(state);

            /**
             * Customizer for lodash.setWith
             */
            const customizer = (value) => {
                if (typeof value !== 'object' || value === null || Array.isArray(value)) {
                    return {};
                }
            };

            action.models.forEach(([model, value]) => {
                setWith(newState, `${action.componentName}.${model}`, value, customizer);
            });

            return newState;

        // Set data to this version
        case types.REMOVEDATA:
            return produce(state, (draft) => {
                delete draft[action.componentName];
            });

        // Remove an item.
        case types.REMOVEITEM:
            return produce(state, (draft) => {
                unset(draft, `${action.componentName}.${action.model}`);
            });

        // Remove items.
        case types.REMOVEITEMS:
            const newStateRemoved = cloneDeep(state);

            action.models.forEach((model) => {
                unset(newStateRemoved, `${action.componentName}.${model}`);
            });

            return newStateRemoved;

        default:
            result = state;
    }

    return result;
};

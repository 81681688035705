
export default { // eslint-disable-line
    CHANGE_LANGUAGE: 'EDITOR_CHANGE_LANGUAGE',
    LOADING: 'EDITOR_LOADING',
    LOAD_SUCCESS:'EDITOR_LOAD_SUCCESS',
    LOAD_ERROR:'EDITOR_LOAD_ERROR',
    CHANGE_MODEL:'EDITOR_CHANGE_MODEL',
    CHANGE_STATE:'EDITOR_CHANGE_STATE',
    IMPORT_DATA:'EDITOR_IMPORT_DATA',
    SAVE:'EDITOR_SAVE',
    RESET:'EDITOR_RESET',
    LOCALSCOPE_RESET:'EDITOR_LOCALSCOPE_RESET',
    LOCALSCOPE_SET:'EDITOR_LOCALSCOPE_SET',
};

import types from './types';

export default (
    state = {
        // eslint-disable-line
        accountId: 0,
        interfaceSetup: {},
        isPending: true,
        domain: ''
    },
    action
) => {
    switch (action.type) {
        case types.FETCH_COMPLETE:
            return {
                ...state,
                isPending: false,
                domain: action.payload.domain,
                accountId: action.payload.accountId,
                interfaceSetup: action.payload.interfaceSetup,
                oAuthLogin: action.payload.oAuthLogin
            };
        case types.FETCH_ERROR:
            return {
                ...state,
                domainError: true,
                domain: action.payload.domain
            };
        default:
            return state;
    }
};

import axios from 'axios';
import * as Sentry from '@sentry/react';
import User from 'components/data/User';

interface VideoThumbnailResponse {
    url: string;
}

/** Service for video related operations. */
class VideoService {
    /**
     * Get the thumbnail of a video based on the provided video url.
     * @param videoUrl Video url.
     * @returns The thumbnail url of the video.
     */
    static getVideoThumbnail = async (videoUrl: string): Promise<string | undefined> => {
        try {
            const { data } = await axios.post<VideoThumbnailResponse | undefined>(
                process.env.APP_MEDIA_URL + 'media/videoStills',
                { url: videoUrl },
                {
                    headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` }
                }
            );

            return data?.url;
        } catch (error) {
            Sentry.captureException(error);
            return undefined;
        }
    };
}

export default VideoService;

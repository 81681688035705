import Request from 'components/data/Request';
import types from './types';
import store from '../../store';

function loadResource(resourceData) {
    const { resource, path = 'resources/load' } = resourceData;

    // Load new resource
    return (dispatch) => {
        // Resource already loaded, return
        if ((store.getState().resources[resource] && store.getState().resources[resource]) || store.getState().resources.resourceList.includes(resource)) {
            dispatch({
                type: 'NONE'
            });
            return;
        }

        dispatch({
            type: types.LOADING,
            payload: {
                resource: resource,
                loading: true
            }
        });

        Request.post(path, { resource: resource })
            .then((data) => {
                dispatch({
                    type: types.LOAD_SUCCESS,
                    payload: {
                        resource: resource,
                        loading: false,
                        data: data.data
                    }
                });
            })
            .catch(() => {
                dispatch({
                    type: types.LOAD_SUCCESS,
                    payload: {
                        resource: resource,
                        loading: false,
                        data: {}
                    }
                });
            });
    };
}

export default { // eslint-disable-line
    loadResource
};

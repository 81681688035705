import types from './types';

export default (state = { // eslint-disable-line
    id: 0,
    type: '',
    loading: false,
    resourceList: []
}, action) => {

    switch (action.type) {
        case types.LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                [action.payload.resource]: action.payload.data
            };
        case types.LOADING:
            return {
                ...state,
                type: action.type,
                loading: true,
                resourceList: [...state.resourceList, action.payload.resource]
            };
        default: return state;

    }
};

import axios, { CancelToken } from 'axios';
import User from 'components/data/User';

/**
 * Represents a video effect applied to a video.
 */
interface VideoEffect {
    type: string;
}

/**
 * Interface representing a crop effect for a video.
 */
export interface CropEffect extends VideoEffect {
    /**
     * The width of the cropped area.
     */
    width: number;
    /**
     * The height of the cropped area.
     */
    height: number;
    /**
     * The x-coordinate of the top-left corner of the cropped area.
     */
    x: number;
    /**
     * The y-coordinate of the top-left corner of the cropped area.
     */
    y: number;
}

/**
 * Represents a video effect that trims a portion of the video.
 */
export interface TrimEffect extends VideoEffect {
    /**
     * The start time of the trimmed portion, in seconds.
     */
    startTime: number;
    /**
     * The duration of the trimmed portion, in seconds.
     */
    duration: number;
}

/**
 * A service that runs video effects on a given video URL using the specified effects.
 */
class VideoModifierService {
    /**
     * Runs video effects on a given video URL using the specified effects.
     * @param videoUrl - The URL of the video to apply effects to.
     * @param effects - An array of effects to apply to the video.
     * @returns A Promise that resolves with the response from the microservice.
     */
    static runVideoEffects = async (videoUrl: string, effects: (TrimEffect & CropEffect)[], outputFormat?: string, cancelToken?: CancelToken) => {
        try {
            // Start microservice
            const response = await axios.post(
                process.env.APP_MEDIA_URL_VIDEO + '',
                {
                    scenes: [
                        {
                            type: 'video',
                            file: videoUrl,
                            conversions: [],
                            effects: effects
                        }
                    ],
                    outputFormat
                },

                { headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` }, cancelToken: cancelToken }
            );

            return response;
        } catch (error) {
            console.log('There was an error using the video editor', error);
        }
    };
}

export default VideoModifierService;

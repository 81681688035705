import AssetHelpers from 'components/data/AssetHelpers';
import initialState from './default';
import types from './types';

export default (state = initialState, action) => { // eslint-disable-line
    switch (action.type) {
        case types.TOGGLE_FILTER_SELECTION:
            return toggleFilterSelection(state, action.payload.filter, action.payload.filterKey);
        case types.SET_ALL_FILTER_SELECTION:
            return setAllFilterSelection(state, action.payload);
        case types.CLEAR_FILTER_SELECTION:
            return clearFilterSelection(state, action.payload);
        case types.CLEAR_ALL_FILTER_SELECTION:
            return clearAllFilterSelection(state, action.payload);
        case types.TOGGLE_ASSET_SELECTION:
            return toggleAssetSelection(state, action.payload);
        case types.SET_ASSET_SELECTION:
            return setAssetSelection(state, action.payload);
        case types.ADD_ASSETS:
            return addAssets(state, action.payload);
        case types.DELETE_ASSETS:
            return deleteAssets(state, action.payload);
        case types.UNBIN_ASSETS:
            return unbinAssets(state, action.payload);
        case types.ADD_ASSET_METADATA:
            return addAssetMetadata(state, action.payload.assetId, action.payload.data);
        case types.ADD_ASSETS_METADATA_BATCH:
            return addAssetsMetadataBatch(state, action.payload);
        case types.ADD_COLLECTION_METADATA:
            return addCollectionMetadata(state, action.payload.value, action.payload.key, action.payload.id);
        case types.FETCH_ASSETS:
            return fetchAssets(state, action.payload);
        case types.FETCH_POPULAR_FILTERS:
            return fetchPopularFilters(state, action.payload);
        case types.FETCH_COLLECTIONS:
            return fetchCollections(state, action.payload);
        case types.FETCH_ALL_COLLECTIONS:
            return fetchAllCollections(state, action.payload);
        case types.ADD_ASSET_TO_COLLECTION:
            return addAssetToCollection(state, action.payload);
        case types.FETCH_COLLECTION_ITEMS:
            return fetchCollectionItems(state, action.payload);
        case types.FETCH_MORE_COLLECTION_ITEMS:
            return fetchMoreCollectionItems(state, action.payload);
        case types.FETCH_CONCEPTS:
            return fetchConcepts(state, action.payload);
        case types.NEW_COLLECTION:
            return newCollection(state, action.payload);
        case types.DELETE_COLLECTION_ITEM:
            return deleteCollectionItem(state, action.payload);
        case types.DELETE_COLLECTION:
            return deleteCollection(state, action.payload);
        case types.FETCH_TOKEN:
            return fetchToken(state, action.payload);
        case types.FETCH_MORE_ASSETS:
            return fetchMoreAssets(state, action.payload);
        case types.FETCH_MORE_COLLECTIONS:
            return fetchMoreCollections(state, action.payload);
        case types.UPDATE_SEARCH_QUERY:
            return updateSearchQuery(state, action.payload);
        case types.SET_RESOURCES:
            return setResources(state, action.payload);
        case types.SET_LOCATION_VIEW:
            return setLocationView(state, action.payload);
        case types.FETCH_ASSET_COUNT:
            return fetchAssetCount(state, action.payload);
        case types.SET_COLLECTION_REFERENCE_ID:
            return setCollectionReferenceId(state, action.payload);
        case types.SET_ASSET_SAVED:
            return setAssetSaved(state, action.payload);
        case types.SET_ASSETS_BATCH_SAVED:
            return setAssetsBatchSaved(state, action.payload);
        case types.SET_ASSETS_FETCHED:
            return setAssetsFetched(state, action.payload);
        case types.SET_COLLECTIONS_FETCHED:
            return setCollectionsFetched(state, action.payload);
        case types.SET_ASSET_SORTING:
            return setAssetSorting(state, action.payload);
        case types.SET_CONCEPTS:
            return setConcepts(state, action.payload);
        case types.SET_FILTER_EXPIRED:
            return setFilterExpired(state, action.payload);
        case types.SET_FILTERS_FETCHED:
            return setFiltersFetched(state, action.payload);
        case types.EMPTY_BIN:
            return emptyBin(state, action.payload);
        default:
            return state;
    }
};
/**
 * Toggle a filter option.
 * @param {object} state
 * @param {object} filter
 * @param {string} filterKey
 * @returns new Redux state
 */
function toggleFilterSelection(state, filter, filterKey) {
    const { filters } = state;

    // If a unknown filter is specified. Add it to the filters.
    // This makes the acceptedTypes prop possible in a setup without a category filter.
    if (!filters.some((f) => f.name === filter.name)) {
        filters.push(filter);
    }

    const newFilters = filters.map((f) => {
        if (f.name !== filter.name) {
            return f;
        }

        if (f.type === 'single') {
            // always set given value
            return {
                ...f,
                selection: [filterKey]
            };
        } else if (f.type === 'multiple' || f.type === 'multipleSubs') {
            // If a user clicks on a tag, only return this tag as a filter
            if (filter.onTagClick) {
                // It is possible a tag is clicked that is not present in the options. If that is the case the option is added to the filter.
                if (f.options.findIndex((o) => o.key === filterKey < 0)) {
                    f.options.push({
                        key: filterKey,
                        value: filterKey,
                        count: 1
                    });
                }
                return { ...f, selection: [filterKey] };
            }

            // Replace entire selection
            if (Array.isArray(filterKey)) {
                return { ...f, selection: filterKey };
            }

            const target = f.selection.find((s) => s === filterKey);
            if (target) {
                // remove from list
                return { ...f, selection: f.selection.filter((s) => s !== filterKey) };
            }
            // add to list
            return { ...f, selection: [...f.selection, filterKey] };
        }
        return {};
    });

    return {
        ...state,
        filters: newFilters
    };
}

/**
 * Clear all options of one filter.
 * @param {object} state
 * @param {object} filter
 * @returns new Redux state
 */
function clearFilterSelection(state, filter) {
    const { filters } = state;

    const newFilters = filters.map((f) => {
        if (f.name === filter) {
            return {
                ...f,
                selection: []
            };
        }

        return f;
    });

    return {
        ...state,
        filters: newFilters
    };
}

/**
 * Clear all the filters of a certain filterType
 * @param {object} state
 * @param {string} filterType
 * @returns new Redux state
 */
function clearAllFilterSelection(state, filterType) {
    const { filters } = state;

    return {
        ...state,
        filters: filters.map((f) => {
            if (f.filterType === filterType) {
                return { ...f, selection: [] };
            }
            return f;
        })
    };
}

/**
 * Set a number of filters a certain filterType at once
 * @param {object} state
 * @param {object} payload consisting of the fileType and the filters to set.
 * @returns
 */
function setAllFilterSelection(state, payload) {
    const { filters: oldFilters } = state;
    const { filters: newFilters, filterType } = payload;

    const searchQuery = newFilters.s;
    delete newFilters.s;
    return {
        ...state,
        searchQuery: { query: searchQuery },
        filters: oldFilters.map((f) => {
            if (f.filterType === filterType && Object.keys(newFilters).includes(f.name)) {
                return { ...f, selection: newFilters[f.name] };
            }
            return f;
        })
    };
}

/**
 * Toggle the selected state of an asset.
 * @param {object} state
 * @param {string} referenceId
 * @returns new Redux state
 */
function toggleAssetSelection(state, referenceId) {
    let { selectedAssets } = state;

    if (selectedAssets.includes(referenceId)) {
        selectedAssets = selectedAssets.filter((sa) => sa !== referenceId);
    } else {
        selectedAssets = [...selectedAssets, referenceId];
    }

    return {
        ...state,
        selectedAssets
    };
}

/**
 * Remove all assets from the selected asstes array in Redux.
 * @param {object} state
 * @returns new Redux state
 */
function setAssetSelection(state, selectedAssets) {
    return {
        ...state,
        selectedAssets
    };
}

/**
 * Add an asset to the asset list in Redux
 * @param {object} state
 * @param {object} asset
 * @returns new Redux state
 */
function addAssets(state, payload) {
    const { items } = state;
    const { asset, atEnd } = payload;
    const newItems = items.slice();

    // Check if this asset is already present in the list, if so, replace it.
    const availableAssetIndex = newItems.findIndex((i) => i.referenceId === asset.referenceId);

    if (availableAssetIndex >= 0) {
        newItems[availableAssetIndex] = asset;
    } else if (atEnd) {
        newItems.push(asset);
    } else {
        newItems.unshift(asset);
    }

    return {
        ...state,
        items: newItems
    };
}

/**
 * Remove assets from the asset list in Redux
 * @param {object} state
 * @param {array} assets
 * @returns new Redux state
 */
function deleteAssets(state, assets) {
    const { items, collectionItems, selectedAssets } = state;
    let newCollectionItems = { ...collectionItems };

    const newItems = items.filter((item) => {
        return !assets.includes(item.referenceId);
    });

    const newSelectedAssets = selectedAssets.filter((referenceId) => {
        return !assets.includes(referenceId);
    });

    // Also remove these assets from collections it belongs to, if these collections are allready loaded in Redux.
    Object.keys(newCollectionItems).forEach((key) => {
        newCollectionItems = {
            ...newCollectionItems,
            [key]: newCollectionItems[key].filter((item) => {
                return !assets.includes(item.referenceId);
            })
        };
    });

    return {
        ...state,
        items: newItems,
        collectionItems: newCollectionItems,
        selectedAssets: newSelectedAssets
    };
}

/**
 * Clear unbinned assets from Redux, if the view is "binned".
 * @param {object} state current Redux state
 * @param {array} assets reference id's of assets to unbin.
 * @returns new Redux state
 */
function unbinAssets(state, assets) {
    let { items } = state;
    const { locationView } = state;

    if (locationView === 'binned') {
        items = items.filter((item) => {
            return !assets.includes(item.referenceId);
        });
    }

    return {
        ...state,
        items: items,
        selectedAssets: []
    };
}

/**
 * Update the metadata of an asset.
 * @param {object} state
 * @param {string} assetId
 * @param {object} data
 * @returns new Redux state
 */
function addAssetMetadata(state, assetId, data) {
    let { items } = state;
    const { collectionItems } = state;

    items = items.map((item) => {
        if (item.referenceId !== assetId) return item;

        return AssetHelpers.patchReduxItem(item, data);
    });

    // Update die assets in all collections in Redux where it is present.
    if (collectionItems) {
        Object.keys(collectionItems).forEach((key) => {
            if (Array.isArray(collectionItems[key])) {
                collectionItems[key] = collectionItems[key].map((collectionItem) => {
                    if (collectionItem.referenceId !== assetId) return collectionItem;

                    return AssetHelpers.patchReduxItem(collectionItem, data);
                });
            }
        });
    }

    return {
        ...state,
        items,
        collectionItems
    };
}

/**
 * Update the metadata of a batch of assets.
 * @param {object} state
 * @param {array} data
 */
function addAssetsMetadataBatch(state, data) {
    let { items } = state;
    const { collectionItems } = state;
    data.forEach((asset) => {
        items = items.map((item) => {
            if (item.referenceId !== asset.referenceId) return item;

            return AssetHelpers.patchReduxItem(item, asset);
        });

        // Update die assets in all collections in Redux where it is present.
        if (collectionItems) {
            Object.keys(collectionItems).forEach((key) => {
                if (Array.isArray(collectionItems[key])) {
                    collectionItems[key] = collectionItems[key].map((collectionItem) => {
                        if (collectionItem.referenceId !== asset.referenceId) return collectionItem;

                        return AssetHelpers.patchReduxItem(collectionItem, data);
                    });
                }
            });
        }
    });

    return {
        ...state,
        items,
        collectionItems
    };
}

/**
 * Update the value of one key of the collection metadata in Redux
 * @param {object} state
 * @param {*} value
 * @param {string} key
 * @param {string} referenceId
 * @returns new Redux state
 */
function addCollectionMetadata(state, value, key, referenceId) {
    const { collections } = state;
    const newCollections = collections.slice();

    newCollections.forEach((collection) => {
        if (collection.referenceId === referenceId) {
            collection[key] = value;
        }
    });

    return {
        ...state,
        collections: newCollections
    };
}

/**
 * Store the fetched collections in Redux
 * @param {object} state
 * @param {object} payload
 * @returns new Redux state
 */
function fetchCollections(state, payload) {
    const metadataInputs = state.metadataInputs.map((input) => {
        if (input.name !== 'collections') {
            return input;
        }

        return { ...input, items: payload.collections };
    });

    return {
        ...state,
        collections: payload.collections,
        collectionsFetched: true,
        metadataInputs: metadataInputs,
        collectionsNext: payload.next,
        collectionsFiltered: payload.collectionsFiltered
    };
}

/**
 * Append the fetched collections to the collections list in Redux
 * @param {object} state
 * @param {object} payload
 * @returns new Redux state
 */
function fetchMoreCollections(state, payload) {
    return {
        ...state,
        collections: [...state.collections, ...payload.collections],
        collectionsNext: payload.next,
        collectionsFiltered: payload.collectionsFiltered
    };
}

/**
 * Set the list of all collections (including sub collections) in Redux.
 * @param {object} state
 * @param {array} payload
 * @returns new Redux state
 */
function fetchAllCollections(state, payload) {
    return {
        ...state,
        allCollections: payload,
        allCollectionsFetched: true
    };
}

/**
 * Store the fetched assets in Redux
 * @param {object} state
 * @param {object} payload
 * @returns new Redux state
 */
function fetchAssets(state, payload) {
    return {
        ...state,
        items: payload.assets,
        assetsFetched: true,
        itemsNext: Array.isArray(payload.assets) && payload.assets.length > 0 ? payload.next : -1,
        assetsFiltered: payload.assetsFiltered
    };
}

/**
 * Append the fetched asset to the asset list in Redux.
 * @param {object} state
 * @param {object} payload
 * @returns new Redux state
 */
function fetchMoreAssets(state, payload) {
    return {
        ...state,
        items: [...state.items, ...payload.assets],
        itemsNext: Array.isArray(payload.assets) && payload.assets.length > 0 ? payload.next : -1,
        assetsFiltered: payload.assetsFiltered
    };
}

/**
 * Store the asset count in Redux.
 * The asset count holds the amount of binned and reviewed assets in the library.
 * @param {object} state
 * @param {object} payload
 * @returns new Redux state
 */
function fetchAssetCount(state, payload) {
    return {
        ...state,
        ...payload
    };
}

/**
 * Store the filters in Redux
 * @param {object} state
 * @param {array} payload
 * @returns new Redux state
 */
function fetchPopularFilters(state, payload) {
    return {
        ...state,
        filters: payload,
        filtersFetched: true
    };
}

/**
 * Toggle the filtersFetched key
 * @param {object} state
 * @param {boolean} payload
 * @returns new Redux state
 */
function setFiltersFetched(state, payload) {
    return {
        ...state,
        filtersFetched: payload
    };
}

/**
 * Ad an asset to the collection items list in Redux if the list was allready present.
 * @param {object} state
 * @param {object} payload
 * @returns New Redux state
 */
function addAssetToCollection(state, payload) {
    const { items, collectionItems } = state;
    const { collectionReferenceId, assetReferenceId } = payload;
    const asset = items.find((i) => i.referenceId === assetReferenceId);

    if (asset && collectionItems && collectionItems[collectionReferenceId]) {
        collectionItems[collectionReferenceId].unshift(asset);
    }

    return {
        ...state,
        collectionItems: {
            ...collectionItems,
            [collectionReferenceId]: collectionItems[collectionReferenceId]
        }
    };
}

/**
 * Store fetched collection items in Redux
 * @param {object} state
 * @param {object} payload
 * @returns New Redux state
 */
function fetchCollectionItems(state, payload) {
    const collectionItems = {
        ...state.collectionItems,
        [payload.collectionReferenceId]: payload.items
    };

    const collectionSubcollections = {
        ...state.collectionSubcollections,
        [payload.collectionReferenceId]: payload.collections
    };

    // Keep track of collections in Redux for which the items have been filtered.
    const collectionItemsFiltered = [...state.collectionItemsFiltered];
    if (payload.filtered && !collectionItemsFiltered.includes(payload.collectionReferenceId)) {
        collectionItemsFiltered.push(payload.collectionReferenceId);
    } else {
        collectionItemsFiltered.splice(collectionItemsFiltered.indexOf(payload.collectionReferenceId), 1);
    }

    return {
        ...state,
        collectionItems,
        collectionItemsNext: {
            ...state.collectionItemsNext,
            [payload.collectionReferenceId]: Array.isArray(payload.items) && payload.items.length ? payload.next : -1
        },
        collectionItemsFiltered,
        collectionSubcollections,
        assetsFetched: true
    };
}

/**
 * Append fetched collection items to the collections items list in Redux.
 * @param {object} state
 * @param {object} payload
 * @returns New Redux state
 */
function fetchMoreCollectionItems(state, payload) {
    const collectionItems = {
        ...state.collectionItems,
        [payload.collectionReferenceId]: [...state.collectionItems[payload.collectionReferenceId], ...payload.items]
    };

    return {
        ...state,
        collectionItems,
        collectionItemsNext: {
            ...state.collectionItemsNext,
            [payload.collectionReferenceId]: Array.isArray(payload.items) && payload.items.length > 0 ? payload.next : -1
        },
        collectionReferenceId: payload.collectionReferenceId
    };
}

/**
 * Combine the concepts received from the server with the uploadsInprogress allready present in Redux.
 * @param {object} state
 * @param {array} concepts
 * @returns New Redux state
 */
function fetchConcepts(state, concepts) {
    let { uploadsInProgress } = state;
    // Filter out the uploadsInProgress that are also present in the concepts retreived from the API.
    uploadsInProgress = uploadsInProgress.filter((u) => concepts.findIndex((c) => c.referenceId === u.referenceId) < 0);

    return {
        ...state,
        uploadsInProgress: [...uploadsInProgress, ...concepts],
        uploadsInProgressFetched: true
    };
}

/**
 * Replace the uploadsInprogress list in Redux with an updated list.
 * @param {object} state
 * @param {array} uploadsInProgress
 * @returns New Redux state
 */
function setConcepts(state, uploadsInProgress) {
    return {
        ...state,
        uploadsInProgress
    };
}

/**
 * Append a collection to the collection list in Redux
 * @param {object} state
 * @param {object} payload
 * @returns New Redux state
 */
function newCollection(state, payload) {
    return {
        ...state,
        collections: [payload, ...state.collections]
    };
}

/**
 * Remove a collection items from the collection items list in Redux
 * @param {object} state
 * @param {object} payload
 * @returns New Redux state
 */
function deleteCollectionItem(state, payload) {
    const collectionItems = { ...state.collectionItems };

    if (collectionItems && collectionItems[payload.collectionReferenceId]) {
        collectionItems[payload.collectionReferenceId] = collectionItems[payload.collectionReferenceId].filter(
            (asset) => asset.referenceId !== payload.assetReferenceId
        );
    }

    return {
        ...state,
        collectionItems
    };
}

/**
 * Remove a collection fom the list iin Redux
 * @param {object} state
 * @param {string} payload
 * @returns New Redux state
 */
function deleteCollection(state, payload) {
    let collections = [...state.collections];

    if (collections && collections.length > 0) {
        collections = collections.filter((collection) => collection.referenceId !== payload);
    }

    return {
        ...state,
        collections
    };
}

/**
 * Store the Content Space access token in Redux
 * @param {object} state
 * @param {string} payload
 * @returns New Redux state
 */
function fetchToken(state, payload) {
    return {
        ...state,
        token: payload
    };
}

/**
 * Store the MetaDataIputs, the editable MetaDataIputs, the required MetaDataIputs and the MetaDataIputs grouping in Redux.
 * @param {object} state
 * @param {object} payload
 * @returns New Redux state
 */
function setResources(state, payload) {
    const { standardAssetFields, customAssetFields, assetFieldsGrouping, assetFilterOrder, fileType } = payload;
    const metadataInputs = [];
    let metadataInputsGrouped = [];
    let accept = null;

    if (standardAssetFields) {
        standardAssetFields.forEach((input) => {
            if (input.name && input.key) {
                metadataInputs.push(input);
            }
        });
    }

    if (customAssetFields) {
        customAssetFields.forEach((input) => {
            if (input.name && input.key) {
                metadataInputs.push({ ...input, custom: true });
            }
        });
    }

    const metadataInputsEditable = AssetHelpers.getInputs(metadataInputs);
    const metadataInputsRequired = AssetHelpers.getRequiredInputs(metadataInputsEditable);

    if (assetFieldsGrouping) {
        metadataInputsGrouped = AssetHelpers.getCustomGroupedInputs(metadataInputsEditable, assetFieldsGrouping);
    } else {
        metadataInputsGrouped = AssetHelpers.getDefaultGroupedInputs(metadataInputsEditable);
    }

    // Set accepted filetype if specified.
    if (fileType) {
        accept = AssetHelpers.getAccept(fileType);
    }

    return {
        ...state,
        metadataInputs,
        metadataInputsRequired,
        metadataInputsGrouped,
        accept,
        assetFilterOrder: assetFilterOrder ? assetFilterOrder : ['categories', 'brands', 'departments', 'markets', 'tags', 'vision_tags', 'license']
    };
}

/**
 * Set the location view in Redux.
 * When switching view, reset the selected assets.
 * @param {object} state
 * @param {string} payload
 * @returns New Redux state
 */
function setLocationView(state, payload) {
    return {
        ...state,
        locationView: payload,
        selectedAssets: []
    };
}

/**
 * Update the search query as stored in Redux.
 * @param {object} state
 * @param {string} payload
 * @returns New Redux state
 */
function updateSearchQuery(state, payload) {
    return {
        ...state,
        searchQuery: payload
    };
}

/**
 * Store the collection reference is of the currently opened collection.
 * When openeing a new collection, reset the selected assets.
 * @param {object} state
 * @param {string} payload
 * @returns New Redux state
 */
function setCollectionReferenceId(state, payload) {
    return {
        ...state,
        collectionReferenceId: payload,
        selectedAssets: []
    };
}

/**
 * Store the current sorting params in Redux
 * @param {object} state
 * @param {object} payload
 * @returns New Redux state
 */
function setAssetSorting(state, payload) {
    let sorting = { ...payload };
    if (payload.sortField === 'createdAt' && payload.sortDirection === -1) {
        sorting = {};
    }
    if (state.collectionReferenceId) {
        //Store the sorting for each collection.
        return {
            ...state,
            collectionItemsSorting: {
                ...state.collectionItemsSorting,
                [state.collectionReferenceId]: sorting
            }
        };
    } else {
        // Store the asset sorting.
        return {
            ...state,
            assetSorting: sorting
        };
    }
}

/**
 * Keep track of saved assets by putting their referenceId in an array or emoving it from the array if it is allready present.
 * @param {object} state
 * @param {string} payload asset referenceId to add or remove, if false, clear the list
 * @returns New Redux state
 */
function setAssetSaved(state, payload) {
    const { savedAssets } = state;
    if (!!payload && !savedAssets.includes(payload)) {
        return {
            ...state,
            savedAssets: [payload, ...savedAssets]
        };
    } else if (!!payload && savedAssets.includes(payload)) {
        const index = savedAssets.indexOf(payload);
        savedAssets.splice(index, 1);
        return {
            ...state,
            savedAssets
        };
    } else {
        return {
            ...state,
            savedAssets: []
        };
    }
}

/**
 *
 * @param {object} state
 * @param {array} payload array of asset referenceId's to add or remove, if false, clear the list
 * @returns New Redux state
 */
function setAssetsBatchSaved(state, payload) {
    const { savedAssets } = state;

    const newSavedAssets = [...savedAssets];

    if (!payload || !Array.isArray(payload)) {
        return {
            ...state,
            savedAssets: []
        };
    }
    payload.forEach((referenceId) => {
        if (!savedAssets.includes(referenceId)) {
            newSavedAssets.push(referenceId);
        } else {
            const index = newSavedAssets.indexOf(referenceId);
            newSavedAssets.splice(index, 1);
        }
    });
    return {
        ...state,
        savedAssets: newSavedAssets
    };
}

/**
 * Set the assetsFetched flag, used to prevent unnecessary API calls
 * @param {object} state
 * @param {boolean} payload
 * @returns New Redux state
 */
function setAssetsFetched(state, payload) {
    return {
        ...state,
        assetsFetched: payload
    };
}

/**
 * Set the collectionsFetched flag, used to prevent unnecessary API calls
 * @param {object} state
 * @param {boolean} payload
 * @returns New Redux state
 */
function setCollectionsFetched(state, payload) {
    return {
        ...state,
        collectionsFetched: payload
    };
}

/**
 * Reset the asset list if the current view is the bin
 * @param {object} state
 * @returns New Redux state
 */
function emptyBin(state) {
    const { locationView } = state;

    if (locationView === 'binned') {
        return {
            ...state,
            items: []
        };
    }
}

/**
 * Set the filter expired flag and rest all loaded results. Should be true for use in campaigns and false for the Asset management.
 * @param {object} state
 * @param {boolean} payload
 * @returns New Redux state
 */
function setFilterExpired(state, payload) {
    return {
        ...state,
        filterExpired: payload,
        items: [],
        itemsNext: 0,
        collectionItems: {},
        collectionItemsNext: {},
        assetsFetched: false,
        assetsFiltered: false,
        collectionsFetched: false,
        collectionsFiltered: false,
        collectionItemsFiltered: []
    };
}

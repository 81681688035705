import queryString from 'query-string';
import types from './types';

// Get tokens and id
const localId = window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 0;
const localToken = window.localStorage.getItem('userToken') ? window.localStorage.getItem('userToken') : 0;
const tokenSSOUserId =
    queryString.parse(window.location.search).sso && queryString.parse(window.location.search).userId ? queryString.parse(window.location.search).userId : '';
const tokenSSO =
    queryString.parse(window.location.search).sso && queryString.parse(window.location.search).token ? queryString.parse(window.location.search).token : '';

export default (
    state = {
        // eslint-disable-line
        role: 0,
        roleName: '',
        id: localId,
        token: localToken,
        tokenSSOUserId: tokenSSOUserId,
        tokenSSO: tokenSSO,
        previewToken: '',
        rights: ['guest'],
        isAuthenticated: false,
        isPending: localId || tokenSSO ? true : false
    },
    action
) => {
    switch (action.type) {
        case types.LOGOUT_PENDING:
            return { ...state, actionType: action.type };
        case types.LOGOUT_SUCCESS:
            return { actionType: action.type, ...action.payload };
        case types.LOGOUT_ERROR:
            return { actionType: action.type, ...action.payload };
        case types.LOGIN_PENDING:
            return { actionType: action.type, ...action.payload };
        case types.LOGIN_SUCCESS:
            return { actionType: action.type, ...action.payload, animationStarted: state.animationStarted };
        case types.LOGIN_ERROR:
            return { actionType: action.type, ...action.payload, animationStarted: false };
        case types.LOGIN_ANIMATION:
            return { ...state, ...action.payload };
        case types.LOGIN_ANIMATION_END:
            return { ...state, ...action.payload };
        case types.TOKEN_LOGIN_PENDING:
            return { ...state, actionType: action.type, ...action.payload };
        case types.TOKEN_LOGIN_SUCCESS:
            return { ...state, actionType: action.type, ...action.payload };
        case types.TOKEN_LOGIN_ERROR:
            return { actionType: action.type, ...action.payload };
        case types.PREVIEW_LOGIN_SUCCESS:
            return { ...state, actionType: action.type, ...action.payload };
        case types.USER_PROFILE_SUCCESS:
            return { ...state, actionType: action.type, ...action.payload };
        case types.USER_PROFILE_ERROR:
            return { actionType: action.type, ...action.payload };
        case types.TOKEN_REFRESH:
            return { ...state, actionType: action.type, ...action.payload };
        case types.USER_PROFILE_SET:
            return { ...state, actionType: action.type, ...action.payload };
        default:
            return state;
    }
};

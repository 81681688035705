import * as Sentry from '@sentry/react';
import Request from 'components/data/Request';
import { CommentAllResponse, CommentResponse, TaggeableUser } from './comments.response';
class CommentsService {
    static async getAllComments(entity: string, campaignId?: string | number | boolean, link?: string): Promise<CommentAllResponse | undefined> {
        try {
            const response = await Request.post('comment/getAll', { entity, campaignId: campaignId ?? false, link });
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    static async getGeneralComments(entity: string, campaignId?: string | number | boolean, link?: string): Promise<CommentAllResponse | undefined> {
        try {
            const response = await Request.post('comment/getGeneral', { entity, campaignId: campaignId ?? false, link });
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    static async getTaggableUsers(entity: string): Promise<TaggeableUser[] | undefined> {
        try {
            const response = await Request.post('profiles/userlist', { entity });
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    static async saveComment(comment: {
        id: string | number;
        campaignId?: string | number | boolean;
        entity: string;
        text: string;
        parentId?: string | number;
        status?: string;
        fileName?: string;
        fileUrl?: string;
        categoryKey?: string;
        categoryLabel?: string;
        x?: string;
        y?: string;
        time?: string;
    }): Promise<TaggeableUser[] | undefined> {
        try {
            const response = await Request.post('comment/save', comment);
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw error;
        }
    }

    static async deleteComment(id: string | number, campaignId?: string | number): Promise<void> {
        try {
            await Request.post('comment/remove', { id, campaignId });
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    static async getComment(entity: string, campaignId: string | number, title: string): Promise<CommentResponse | undefined> {
        try {
            const response = await Request.post('comment/get', { entity, campaignId, title });
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
        }
    }
}

export { CommentsService };
